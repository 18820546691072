import type { CvData } from "@/composables/types";
import PreviewTemplateBebek from "@/views/cv-creation-view/cv-creation/cv-preview/cv-preview-templates/PreviewTemplateBebek.vue";
import PreviewTemplateBussiness from "@/views/cv-creation-view/cv-creation/cv-preview/cv-preview-templates/PreviewTemplateBussiness.vue";
import PreviewTemplateCasual from "@/views/cv-creation-view/cv-creation/cv-preview/cv-preview-templates/PreviewTemplateCasual.vue";
import PreviewTemplateClassic from "@/views/cv-creation-view/cv-creation/cv-preview/cv-preview-templates/PreviewTemplateClassic.vue";
import PreviewTemplate from "@/views/cv-creation-view/cv-creation/cv-preview/cv-preview-templates/PreviewTemplateDefault.vue";
import PreviewTemplateFancy from "@/views/cv-creation-view/cv-creation/cv-preview/cv-preview-templates/PreviewTemplateFancy.vue";
import PreviewTemplateLiner from "@/views/cv-creation-view/cv-creation/cv-preview/cv-preview-templates/PreviewTemplateLiner.vue";
import PreviewTemplateModa from "@/views/cv-creation-view/cv-creation/cv-preview/cv-preview-templates/PreviewTemplateModa.vue";
import PreviewTemplateProfessional from "@/views/cv-creation-view/cv-creation/cv-preview/cv-preview-templates/PreviewTemplateProfessional.vue";

export const returnTemplateComponent = (cvData: CvData) => {

  switch (cvData.selectedTemplate) {
  case "Classic":
    return { component: PreviewTemplateClassic };
  case "Casual":
    return { component: PreviewTemplateCasual };
  case "Business":
    return { component: PreviewTemplateBussiness };
  case "Professional":
    return { component: PreviewTemplateProfessional };
  case "Fancy":
    return { component: PreviewTemplateFancy };
  case "Bebek":
    return { component: PreviewTemplateBebek };
  case "Liner":
    return { component: PreviewTemplateLiner };
  case "Moda":
    return { component: PreviewTemplateModa };
  default:
    return { component: PreviewTemplate };
  }
};