<template>
  <div class="password-page">
    <div class="password-container">
      <h1>Enter Password</h1>
      <input v-model="password" type="password" placeholder="Enter password" />
      <button @click="submitPassword">Submit</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

import axios from "axios";
import { useRouter } from "vue-router";

import { API_URL } from "@/config";

const password = ref("");
const router = useRouter();

const submitPassword = async () => {
  try {
    const response = await axios.post(API_URL + "/api/verify-password", { password: password.value });
    if (response.data.success) {
      localStorage.setItem("isPasswordEntered", "true");
      router.push("/");
    } else {
      alert("Incorrect password");
    }
  } catch (error) {
    console.error("Error verifying password:", error);
    alert("An error occurred while verifying the password.");
  }
};
</script>

  <style>
  .password-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
  }

  .password-container {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .password-container h1 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }

  .password-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .password-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #42b983;
    color: white;
    cursor: pointer;
    font-size: 16px;
  }

  .password-container button:hover {
    background-color: #36a373;
  }
  </style>
