import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import type { RouteLocationNormalizedLoaded } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

import { addToHistory } from "@/composables/useNavigationHistory";
import { API_URL } from "@/config";
import AboutUsPage from "@/pages/AboutUsPage.vue";
import CancellationPolicyPage from "@/pages/CancellationPolicyPage.vue";
import ContactUsPage from "@/pages/ContactUsPage.vue";
import CvCreationPage from "@/pages/CvCreationPage.vue";
import CvEvaluationPage from "@/pages/CvEvaluationPage.vue";
import CvExistingPage from "@/pages/CvExistingPage.vue";
import ErrorPage from "@/pages/ErrorPage.vue";
import FaqPage from "@/pages/FaqPage.vue";
import Home from "@/pages/HomePage.vue";
import ImprintPage from "@/pages/ImprintPage.vue";
import Library from "@/pages/LibraryPage.vue";
import MyAccount from "@/pages/MyAccountPage.vue";
import PasswordPage from "@/pages/PasswordPage.vue";
import PaymentPage from "@/pages/PaymentPage.vue";
import PricingPage from "@/pages/PricingPage.vue";
import PrivacyPolicyPage from "@/pages/PrivacyPolicyPage.vue";
import TermsAndConditionsPage from "@/pages/TermsAndConditionsPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: (route: RouteLocationNormalizedLoaded) => ({ sessionExpired: route.params.sessionExpired }),
  },
  {
    path: "/password",
    name: "Password",
    component: PasswordPage,
  },
  {
    path: "/account",
    name: "Account",
    component: MyAccount,
  },
  {
    path: "/library",
    name: "Library",
    component: Library,
    meta: { requiresAuth: true },
  },
  {
    path: "/cv-creation/:cvId",
    name: "CvCreation",
    component: CvCreationPage,
    props: true,
    meta: { requiresAuth: true, requiresCvOwnership: true },
  },
  {
    path: "/cv-existing",
    name: "CvExisting",
    component: CvExistingPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/cv-evaluation",
    name: "CvEvalutation",
    component: CvEvaluationPage,
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: PricingPage,
  },
  {
    path: "/payment",
    name: "Payment",
    component: PaymentPage,
  },
  {
    path: "/about",
    name: "About",
    component: AboutUsPage,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactUsPage,
  },
  {
    path: "/faq",
    name: "Faq",
    component: FaqPage,
  },
  {
    path: "/policies/imprint",
    name: "Imprint",
    component: ImprintPage,
  },
  {
    path: "/policies/terms-and-conditions",
    name: "Terms-and-conditions",
    component: TermsAndConditionsPage,
  },
  {
    path: "/policies/privacy-policy",
    name: "Privacy-policy",
    component: PrivacyPolicyPage,
  },
  {
    path: "/policies/cancellation-policy",
    name: "Cancellation-policy",
    component: CancellationPolicyPage,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/error",
  },
  {
    path: "/error",
    name: "Error",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (_to, _from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, _from, next) => {

  addToHistory(_from.fullPath);

  const isPasswordEntered = localStorage.getItem("isPasswordEntered");
  if (!isPasswordEntered && to.name !== "Password") {
    return next({ name: "Password" });
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresCvOwnership = to.matched.some(record => record.meta.requiresCvOwnership);

  if (requiresAuth) {
    const user = await new Promise(resolve => {
      const auth = getAuth();
      onAuthStateChanged(auth, user => resolve(user));
    });

    if (!user) {
      return next("/");
    }

    const jwt = localStorage.getItem("jwt");
    if (!jwt) {
      return next("/");
    }

    if (requiresCvOwnership) {
      const { cvId } = to.params;
      try {
        const response = await axios.get(API_URL + `/api/verify-cv-ownership/${cvId}`, {
          headers: {
            "Authorization": `Bearer ${jwt}`,
          },
        });

        if (response.status === 200) {
          next();
        } else {
          next("/");
        }
      } catch (_error) {
        next("/");
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
