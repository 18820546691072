<template>
  <div id="app">
    <SmallScreenMessage />
    <Header v-if="route.name !== 'Password'" />
    <router-view />
    <Footer v-if="route.name !== 'Password'" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

import { useRoute } from "vue-router";

import Footer from "./components/ResumifyFooter.vue";
import Header from "./components/ResumifyHeader.vue";
import SmallScreenMessage from "./pages/ScreenSizeSmall.vue";

const route = useRoute();

const allCookiesSelected = ref(false);
const necessaryCookiesSelected = ref(false);

onMounted(() => {
  const script = document.createElement("script");
  script.src = "https://app.cockpit.legal/static/cookieconsent-v2.js?tools=Google%20Analytics,Google%20Tag%20Manager,Amazon%20Web%20Services,Facebook,Fontawesome,Google,Klarna,LinkedIn,PayPal";
  script.setAttribute("data-cc-tools", "Google Analytics,Google Tag Manager,Amazon Web Services,Facebook,Fontawesome,Google,Klarna,LinkedIn,PayPal");
  script.setAttribute("data-cc-privacy", "https://resumify-ai.com/policies/privacy-policy");
  script.setAttribute("data-cc-imprint", "https://resumify-ai.com/policies/imprint");
  script.setAttribute("data-cc-color", "#5438da");
  script.setAttribute("data-cc-theme", "browser");
  script.setAttribute("data-cc-language", "browser");
  script.setAttribute("data-cc-non-eu-consent", "true");

  window.addEventListener("cookieConsentChanged", (event) => {
    const consentedTools = (event as CustomEvent).detail.consentedTools;

    // Check if all cookies are selected
    allCookiesSelected.value = consentedTools.length > 2;

    // Check if only necessary cookies are selected
    necessaryCookiesSelected.value = consentedTools.includes("necessary");
  });
  document.body.appendChild(script);

});

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Montserrat';
}

#app {
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: center;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
