import type { UserDetails } from "@/composables/types";
import useHttp from "@/composables/useHttp";
import { API_URL } from "@/config";

export const saveUserInfo = async (user: { uid: string; email: string; displayName: string | null }) => {
  const { data, error, loading, execute } = useHttp<{userData: UserDetails, id: string}>(API_URL + "/api/user", {
    method: "POST",
  });

  const result = await execute(user);
  return { data, error, loading, result };
};

export const userExist = async (uid: string) => {
  const { data, error, loading, execute } = useHttp<{exists: boolean}>(API_URL + `/api/users/${uid}/exists`, {
    method: "GET",
  });

  const result = await execute();
  return { data, error, loading, result };
};
// eslint any error cant be fixed because userData contains uid
export const modifyUserInfo = async (uid: string, userData: any) => {
  const { data, error, loading, execute } = useHttp<{userData: UserDetails, uid: string}>(API_URL + "/api/user", {
    method: "PUT",
  });

  const result = await execute({ uid, ...userData });
  return { data, error, loading, result };
};

export const updatePremium = async (uid: string, premiumType: string) => {
  const { data, error, loading, execute } = useHttp<UserDetails>(API_URL + "/api/updatePremium", {
    method: "PUT",
  });

  const result = await execute({ uid, premiumType });
  return { data, error, loading, result };
};

export const deleteUser = async (uid: string) => {
  const { data, error, loading, execute } = useHttp<string>(API_URL + "/api/user", {
    method: "DELETE",
  });

  const result = await execute({ uid });
  return { data, error, loading, result };
};

export const getUserInfo = async (uid: string) => {
  const { data, error, loading, execute } = useHttp<UserDetails>(API_URL + `/api/user/${uid}`, {
    method: "GET",
  });

  const result = await execute();
  return { data, error, loading, result };
};
