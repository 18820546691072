import { createI18n } from "vue-i18n";

import de from "./locales/de.json";
import en from "./locales/en.json";
import tr from "./locales/tr.json";

const messages = {
  en,
  de,
  tr,
};

const getUserLocale = (): string => {
  const userLocale = navigator.language || "en";
  const language = userLocale.split("-")[0];
  if (language === "de") {return "de";}
  if (language === "tr") {return "tr";}
  return "en";
};

const i18n = createI18n({
  legacy: false,
  locale: getUserLocale(),
  fallbackLocale: "en",
  messages,
});

export default i18n;
