<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const emit = defineEmits(["updateSkills", "closeModal", "discardEnhanced", "discardAts"]);

defineProps<{
  skillEnhancements: string[],
  atsEnhancements: string[]
}>();

const discardEnhanced = () => {
  emit("discardEnhanced");
  emit("discardAts");
  emit("closeModal");
};

const updateSkills = (skill: string) => {
  if(skill)
  {
    emit("updateSkills", skill );
  }
};

</script>

<template>
  <div class="container-enhance">
    <div class="overlays-wrapper">
      <div class="modal-overlay" @click.stop>
        <div class="found-text">{{ t("EnhanceText") }}</div>
        <div v-for="skill in skillEnhancements" :key="skill" class="modal-skill-div">
          <span class="modal-skill-text">{{ skill }}</span>
          <button class="modal-skill-button" @click="updateSkills(skill)">+</button>
        </div>
      </div>
      <div class="modal-overlay" @click.stop>
        <div class="found-text">{{ t("AtsText") }}</div>
        <div v-for="skill in atsEnhancements" :key="skill" class="modal-skill-div">
          <span class="modal-skill-text">{{ skill }}</span>
          <button class="modal-skill-button" @click="updateSkills(skill)">+</button>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="discard-button"
      @click="discardEnhanced"
    >
      {{ t('Discard') }}
    </button>
  </div>
</template>

  <style scoped>
.container-enhance {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    z-index: 1000;
    background-color: #4f4f4f;
}

.overlays-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}
.modal-overlay {
    background-color: #f4f2fd;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid #4f4f4f;
    border-radius: 8px;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    min-height: 147px;
}

  .modal-skill-button{
    cursor: pointer;
    width: 39px;
    height: 38px;
    padding: 0px 8px;
    border: 1px solid #5438da;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: rgba(0,0,0,0);
    color: #5438da;
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 16px;
    outline: none;
  }

  .discard-button{
  cursor: pointer;
  width: 90px;
  height: 33px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #f0f0f0;
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 24px;
  outline: none;
  margin-right: 15px
}

  .modal-skill-text{
    color: #909090;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 21px;
  }
  .modal-skill-div{
    display: flex;
    width: 100%;
    min-height: 51.5px;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }

  </style>
