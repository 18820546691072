<script setup lang="ts">
import type { Ref } from "vue";
import { ref, onMounted, watch, defineProps, computed } from "vue";

import DOMPurify from "dompurify";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import { useAuth } from "@/auth/useAuth";
import AuthModal from "@/components/AuthModal.vue";
import UpgradePremium from "@/components/UpgradePremium.vue";
import type { MissingInputField , SectionScore } from "@/composables/types";
import { enhanceSummary } from "@/services/enhanceService";
import { modifyLibraryEntry } from "@/services/libraryService";
import { validateInputs } from "@/utils/validateCvDataInputs";

import CvSectionScore from "../../components/CvSectionScore.vue";
import DescriptionEnhanceModal from "../../components/DescriptionEnhanceModal.vue";
import EnhanceButton from "../../components/EnhanceButton.vue";
import RichTextEditor from "../../components/RichTextEditor.vue";
import { removeBackgroundColorsFromSection } from "../../utils/removeDescriptionBackgroundColors";

const { t } = useI18n();

const store = useStore();
const { user } = useAuth();
const showDescriptionModal = ref(false);
const loaded = ref(false);
const enhanced = ref(false);
const isLoading = ref(false);
const upgradePremiumVisible = ref(false);
const authModalVisible = ref(false);
const missingFields = ref<string[]>([]);
const richTextEditorRef = ref<any>();
const creditNotEnough = ref(false);

const closeModal = () => {
  showDescriptionModal.value = false;
};
const openModal = () => {
  showDescriptionModal.value = true;
};

const summaryEnhancements = ref([]);

const props = defineProps<{
  cvId: string,
  evaluated: boolean,
  scoreDetails: SectionScore | undefined
}>();

watch(
  () => store.state.cvData,
  async (newcvData) => {
    try {
      await modifyLibraryEntry(props.cvId, user.value.details?.uid || "", removeBackgroundColorsFromSection(newcvData, "contact"));
      missingFields.value = validateInputs(store.state.cvData, store.state.cvData.contact);
    } catch (error) {
      console.error("Failed to update library entry:", error);
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

const jobTitle = ref("");
const firstName = ref("");
const lastName = ref("");
const email = ref("");
const phone = ref("");
const country = ref("");
const city = ref("");
const birthDate = ref("");
const address = ref("");
const description = ref("");

type FieldRefType = {
  [key: string]: Ref<string>;
};

const fieldRefs: FieldRefType = {
  jobTitle,
  firstName,
  lastName,
  email,
  phone,
  country,
  city,
  birthDate,
  address,
  description,
};

onMounted(async () => {
  const contactData = store.state.cvData.contact;
  Object.keys(contactData).forEach((key) => {
    if (key in fieldRefs) {
      fieldRefs[key].value = contactData[key];
    }
  });
  loaded.value = true;
});

const handleInputUpdate = (field: string, event: Event) => {
  const value = (event.target as HTMLInputElement).value;

  if (fieldRefs[field]) {
    fieldRefs[field].value = DOMPurify.sanitize(value);
  }

  store.commit("updateCvSection", { section: "contact", data: { ...store.state.cvData.contact, [field]: value } });
};

const updateDescription = (newHtmlContent: string) => {
  description.value = DOMPurify.sanitize(newHtmlContent);
  store.commit("updateCvSection", { section: "contact", data: { ...store.state.cvData.contact, ["description"]: description.value } });
};

const updateDescriptionEnhance = async (newHtmlContent: string) => {
  description.value = DOMPurify.sanitize(newHtmlContent);
  store.commit("updateCvSection", { section: "contact", data: { ...store.state.cvData.contact, ["description"]: description.value } });
  setTimeout(() => {
    richTextEditorRef.value?.updateInnerHtml();
  }, 500);
};

const discardEnhanced = async () => {
  enhanced.value = false;
};

const getSummaryEnhancement = async () => {
  if (!user.value.authUser) {
    authModalVisible.value = true;
    return;
  }
  if (user.value.details?.accountStatus !== "Premium") {
    upgradePremiumVisible.value = true;
    return;
  }
  missingFields.value = validateInputs(store.state.cvData, store.state.cvData.contact);
  isLoading.value = true;
  discardEnhanced();
  try {
    const { result: response, error: enhanceError } = await enhanceSummary(description.value, t("Locale"), t("SectionStringSummary"), store.state.cvData.contact.jobTitle, user.value.details.id || "");
    if (enhanceError?.value && enhanceError.value.indexOf("402") !== -1) {
      creditNotEnough.value = true;
      return;
    }

    const jsonString2 = response?.enhancedSummaries;

    const jsonObj = JSON.parse(jsonString2 || "");

    summaryEnhancements.value = jsonObj.enhanced_summaries;

    enhanced.value = true;
    openModal();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  } catch (e) {
    console.error("Error during enhancement or parsing:", e);
  } finally {
    isLoading.value = false;
  }
};

const isFieldMissing = computed(() => {
  return (field: MissingInputField) => missingFields.value.includes(field);
});

</script>

<template>
  <AuthModal v-if="authModalVisible" @update:visible="authModalVisible = $event"/>
  <UpgradePremium v-if="creditNotEnough" :premiumReasonText="t('NotEnoughCreditsTextPremium')" :premiumHeader="t('NotEnoughCreditsHeaderPremium')" @close="creditNotEnough = false"/>
  <UpgradePremium v-if="upgradePremiumVisible" showFeatures @close="upgradePremiumVisible = false"/>
  <div v-if="loaded" class="personal-info-container" @click="closeModal">
    <div class="score-section">
      <div class="details-section">
        <h2>{{ t('AdditionalSection.PersonalDetails') }}</h2>
        <p>{{ t('AdditionalSection.FillOutYourPersonalInformation') }}</p>
      </div>
      <CvSectionScore v-if="scoreDetails && evaluated" :scoreDetails="scoreDetails" />
    </div>

    <div class="input-wrapper">
      <label for="jobTitle">{{ t('AdditionalSection.DesiredJobTitle') }}</label>
      <input id="jobTitle" type="text" :placeholder="t('AdditionalSection.DesiredJobTitle')" :value="jobTitle" @blur="handleInputUpdate('jobTitle', $event)" />
    </div>

    <div class="input-wrapper">
      <DescriptionEnhanceModal v-if="showDescriptionModal" :summaryEnhancements="summaryEnhancements" :currentDescription="description || ''" @updateDescription="updateDescriptionEnhance" @closeModal="closeModal" @discardEnhanced="discardEnhanced"/>
      <label for="description">{{ t('AdditionalSection.Summary') }}</label>
    </div>
    <RichTextEditor ref="richTextEditorRef" :htmlValue="description" :enhanced="enhanced" :style="{ height: '150px' }" @updateHtmlValue="updateDescription" @openModal="openModal"/>
    <div class="input-row">
      <div class="input-wrapper">
        <label for="firstName">{{ t('AdditionalSection.FirstName') }}</label>
        <input id="firstName" type="text" :placeholder="t('AdditionalSection.FirstName')" :value="firstName" :class="{'error': enhanced && isFieldMissing('firstName')}" @blur="handleInputUpdate('firstName', $event)" />
        <span v-if="enhanced && isFieldMissing('firstName')" class="error-message">{{ t("InputValidation.MissingFirstName") }}</span>
      </div>

      <div class="input-wrapper">
        <label for="lastName">{{ t('AdditionalSection.LastName') }}</label>
        <input id="lastName" type="text" :placeholder="t('AdditionalSection.LastName')" :value="lastName" :class="{'error': enhanced && isFieldMissing('lastName')}" @blur="handleInputUpdate('lastName', $event)" />
        <span v-if="enhanced && isFieldMissing('lastName')" class="error-message">{{ t("InputValidation.MissingLastName") }}</span>
      </div>
    </div>

    <div class="input-row">
      <div class="input-wrapper">
        <label for="email">{{ t('AdditionalSection.EmailAddress') }}</label>
        <input id="email" type="text" :placeholder="t('AdditionalSection.EmailAddress')" :value="email" :class="{'error': enhanced && isFieldMissing('email')}" @blur="handleInputUpdate('email', $event)" />
        <span v-if="enhanced && isFieldMissing('email')" class="error-message">{{ t("InputValidation.MissingEmail") }}</span>
      </div>

      <div class="input-wrapper">
        <label for="phone">{{ t('AdditionalSection.PhoneNumber') }}</label>
        <input id="phone" type="text" :placeholder="t('AdditionalSection.PhoneNumber')" :value="phone" :class="{'error': enhanced && isFieldMissing('phone')}" @blur="handleInputUpdate('phone', $event)" />
        <span v-if="enhanced && isFieldMissing('phone')" class="error-message">{{ t("InputValidation.MissingPhoneNumber") }}</span>
      </div>
    </div>

    <div class="input-row">
      <div class="input-wrapper">
        <label for="country">{{ t('AdditionalSection.Country') }}</label>
        <input id="country" type="text" :placeholder="t('AdditionalSection.Country')" :value="country" :class="{'error': enhanced && isFieldMissing('country')}" @blur="handleInputUpdate('country', $event)" />
        <span v-if="enhanced && isFieldMissing('country')" class="error-message">{{ t("InputValidation.MissingCountry") }}</span>
      </div>

      <div class="input-wrapper">
        <label for="city">{{ t('AdditionalSection.City') }}</label>
        <input id="city" type="text" :placeholder="t('AdditionalSection.City')" :value="city" :class="{'error': enhanced && isFieldMissing('city')}" @blur="handleInputUpdate('city', $event)" />
        <span v-if="enhanced && isFieldMissing('city')" class="error-message">{{ t("InputValidation.MissingCity") }}</span>
      </div>
    </div>

    <div class="input-wrapper">
      <label for="birthDate">{{ t('AdditionalSection.DateOfBirth') }}</label>
      <input id="birthDate" type="text" :placeholder="t('AdditionalSection.DateOfBirth')" :value="birthDate" :class="{'error': enhanced && isFieldMissing('birthDate')}" @blur="handleInputUpdate('birthDate', $event)" />
      <span v-if="enhanced && isFieldMissing('birthDate')" class="error-message">{{ t("InputValidation.MissingDateOfBirth") }}</span>
    </div>

    <div class="input-wrapper">
      <label for="address">{{ t('AdditionalSection.Address') }}</label>
      <input id="address" type="text" :placeholder="t('AdditionalSection.Address')" :value="address" :class="{'error': enhanced && isFieldMissing('address')}" @blur="handleInputUpdate('address', $event)" />
      <span v-if="enhanced && isFieldMissing('address')" class="error-message">{{ t("InputValidation.MissingAddress") }}</span>
    </div>

    <div style="margin-top: 10px;">
      <EnhanceButton :isLoading="isLoading" :width="'198px'" :height="'53px'" @enhance="getSummaryEnhancement" />
    </div>

  </div>
</template>

<style scoped>

.error-message {
  color: red;
  font-size: 13px;
}

.details-section {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.score-section {
  display: flex;
  flex-direction: row;
}

.personal-info-container {
  width: 100%;
  margin: auto;
  background: #fff;
  padding: 20px;
  align-items: flex-start;
}

.personal-info-container h2 {
  text-align: start;
}

.personal-info-container p {
  text-align: start;
  color: #7d7d7d;
}

.input-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 50px;
  align-items: flex-start;
}

.input-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-wrapper .error {
  border: 2px solid red !important;
}

.input-wrapper label {
  display: block;
  margin-bottom: 5px;
  color: #909090;
  font-weight: bold;
}

.input-wrapper input[type="text"],
.input-wrapper input[type="email"],
.input-wrapper input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.continue-button {
  background-color: #aa93f3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
}

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
</style>
