import axios from "axios";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signInWithPopup,
  deleteUser,
} from "firebase/auth";

import { API_URL } from "@/config";
import router from "@/router";

const auth = getAuth();

const getCSRFToken = async (): Promise<string> => {
  const response = await axios.get(API_URL + "/csrf-token", { withCredentials: true });
  return response.data.csrfToken;
};

const getJWT = async (idToken: string): Promise<string> => {
  const csrfToken = await getCSRFToken();
  const response = await axios.post(
    API_URL + "/login",
    { idToken },
    {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      withCredentials: true,
    },
  );
  return response.data.token;
};

export const signUp = async (email: string, password: string) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  const idToken = await userCredential.user.getIdToken();
  const jwt = await getJWT(idToken);
  localStorage.setItem("jwt", jwt);
  return userCredential;
};

export const signIn = async (email: string, password: string) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  const idToken = await userCredential.user.getIdToken();
  const jwt = await getJWT(idToken);
  localStorage.setItem("jwt", jwt);
  if (router.currentRoute.value.query.sessionExpired === "true") {
    router.replace({ query: { ...router.currentRoute.value.query, sessionExpired: undefined } });
  }
  return userCredential;
};

export const signOutUser = async (sessionExpired = false) => {
  await signOut(auth);
  localStorage.removeItem("jwt");
  if(sessionExpired){
    router.push({ name: "Home", query: { sessionExpired: "true" } });
  }
  else{
    router.push({ path: "/" });
  }
};

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const userCredential = await signInWithPopup(auth, provider);
  const idToken = await userCredential.user.getIdToken();
  const jwt = await getJWT(idToken);
  localStorage.setItem("jwt", jwt);
  if (router.currentRoute.value.query.sessionExpired === "true") {
    router.replace({ query: { ...router.currentRoute.value.query, sessionExpired: undefined } });
  }
  return userCredential;
};

export const signInWithFacebook = async () => {
  const provider = new FacebookAuthProvider();
  const userCredential = await signInWithPopup(auth, provider);
  const idToken = await userCredential.user.getIdToken();
  const jwt = await getJWT(idToken);
  localStorage.setItem("jwt", jwt);
  if (router.currentRoute.value.query.sessionExpired === "true") {
    router.replace({ query: { ...router.currentRoute.value.query, sessionExpired: undefined } });
  }
  return userCredential;
};

export const signInWithLinkedIn = async () => {
  const provider = new OAuthProvider("linkedin.com");
  const userCredential = await signInWithPopup(auth, provider);
  const idToken = await userCredential.user.getIdToken();
  const jwt = await getJWT(idToken);
  localStorage.setItem("jwt", jwt);
  if (router.currentRoute.value.query.sessionExpired === "true") {
    router.replace({ query: { ...router.currentRoute.value.query, sessionExpired: undefined } });
  }
  return userCredential;
};

export const deleteUserAccount = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("No user is currently signed in.");
  }

  try {
    await deleteUser(user);
    await signOut(auth);
    localStorage.removeItem("jwt");
  } catch (error) {
    console.error("Error deleting user account:", error);
    throw error;
  }
};
