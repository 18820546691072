<script setup lang="ts">
import { onMounted, ref, watch, onUnmounted, nextTick } from "vue";

import { useI18n } from "vue-i18n";

import type { CvData } from "@/composables/types";
import type { HTMLElementWithVNode, ForbiddenZone } from "@/views/cv-creation-view/const";
import { adjustLayout } from "@/views/cv-creation-view/utils/adjustPreview";
import { containsTextOutsideHtml } from "@/views/cv-creation-view/utils/text";

const { cvData, adjust = false } = defineProps<{
  cvData: CvData,
  adjust?: boolean
}>();

const { t } = useI18n();

const divManipulatorRef = ref<HTMLElementWithVNode | null>(null);
const forbiddenZones = ref<ForbiddenZone[]>([]);

const updateForbiddenZones = () => {
  const children = divManipulatorRef.value ? Array.from(divManipulatorRef.value.children) as HTMLElement[] : [];
  if (children.length > 0) {
    const rectForFirst = children[0].getBoundingClientRect();
    console.log(children[0].getBoundingClientRect());
    forbiddenZones.value = [
      { start: rectForFirst.y + 740, end: rectForFirst.y + 840 },
      { start: rectForFirst.y + 1590, end: rectForFirst.y + 1680 },
      { start: rectForFirst.y + 2350, end: rectForFirst.y + 2490 },
      { start: rectForFirst.y + 3150, end: rectForFirst.y + 3290 },
    ];
  }
};

const updateLeftSectionHeight = () => {
  const leftSection = divManipulatorRef.value?.querySelector(".left-section") as HTMLElement | null;
  if (leftSection) {
    const contentHeight = leftSection.scrollHeight;
    const height196vh = window.innerHeight * 1.96;
    const height392vh = window.innerHeight * 3.92;
    const height588vh = window.innerHeight * 5.88;
    const height784vh = window.innerHeight * 7.84;

    if (contentHeight <= height196vh) {
      leftSection.style.minHeight = "196vh";
    } else if (contentHeight > height196vh && contentHeight <= height392vh) {
      leftSection.style.minHeight = "392vh";
    } else if (contentHeight > height392vh && contentHeight <= height588vh) {
      leftSection.style.minHeight = "588vh";
    } else if (contentHeight > height588vh && contentHeight <= height784vh) {
      leftSection.style.minHeight = "784vh";
    } else {
      leftSection.style.minHeight = `${contentHeight}px`;
    }
  }
};

watch(() => cvData, () => {

  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }
}, { deep: true, immediate: true });

watch(cvData, (newValue) => {
  const fontSize = `${newValue.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
});

onMounted(async () => {
  console.log(cvData);
  await nextTick();
  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }

  const fontSize = `${cvData.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
  window.addEventListener("resize", () => {
    updateForbiddenZones();
    updateLeftSectionHeight();
    if(!adjust)
    {
      updateLeftSectionHeight();
      adjustLayout(forbiddenZones.value, divManipulatorRef);
    }
  });
});

onUnmounted(() => {
  window.removeEventListener("resize", () => {
    updateForbiddenZones();
    updateLeftSectionHeight();
    if(!adjust)
    {
      updateLeftSectionHeight();
      adjustLayout(forbiddenZones.value, divManipulatorRef);
    }
  });
});
</script>

<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
  <div ref="divManipulatorRef" class="div-manipulator" :style="{ '--resume-color': cvData.resumeColor, fontFamily: cvData.resumeFontFamily }">
    <section class="resume">
      <div class="center-container">
        <div v-if="cvData.contact?.firstName || cvData.contact?.lastName" class="person-name">
          {{ cvData.contact?.firstName }} {{ cvData.contact?.lastName }}
        </div>
        <div v-if="cvData.contact?.jobTitle" class="job-title">{{ cvData.contact?.jobTitle }}</div>
        <div class="line"></div>
        <div v-if="cvData.contact?.description && containsTextOutsideHtml(cvData.contact?.description)" class="descriptions" style="padding-left: 4px;"
             v-html="cvData.contact?.description"></div>
        <div class="section-name">{{ t("Contact") }}</div>
        <div class="horizontal-adjuster" style="padding-left: 4px;">
          <div v-if="cvData.contact?.phone" class="sub-title" style="padding-right: 7px;">{{ cvData.contact?.phone }}</div>
          <div v-if="cvData.contact?.email" class="sub-title" style="padding-right: 7px;"> {{ cvData.contact?.email }}</div>
          <div v-if="cvData.contact?.city" class="sub-title" style="padding-right: 7px;">{{ cvData.contact?.city }}</div>
          <div v-if="cvData.contact?.country" class="sub-title" style="padding-right: 7px;">{{ cvData.contact?.country }}</div>
          <div v-if="cvData.contact?.address" class="sub-title" style="padding-right: 7px;">{{ cvData.contact?.address }}</div>
          <div v-if="cvData.contact?.birthDate" class="sub-title" style="padding-right: 7px;">{{ cvData.contact?.birthDate }}</div>
        </div>
        <div v-if="cvData.experiences && cvData.experiences.length > 0" class="section-name" style="padding-top: 5px;">{{ t("Experience") }}</div>
        <div v-for="experience in cvData.experiences" :key="experience.id" class="dates-container">
          <div class="section-dates">
            <div class="date">{{ experience.endDate }}</div>
            <div class="date">{{ experience.startDate }}</div>
          </div>
          <div class="dates-details">
            <div v-if="experience.title" class="sub-title" style="font-weight: 600;">{{ experience.title }}</div>
            <div v-if="experience.company" class="sub-title">{{ experience.company }}</div>
            <div v-if="experience.description  && containsTextOutsideHtml(experience.description)" class="descriptions" v-html="experience.description"></div>
          </div>
        </div>
        <div v-if="cvData.educations && cvData.educations.length > 0" class="section-name" style="padding-top: 5px;">{{ t("Education") }}</div>
        <div v-for="education in cvData.educations" :key="education.id" class="dates-container">
          <div class="section-dates">
            <div class="date">{{ education.endDate }}</div>
            <div class="date">{{ education.startDate }}</div>
          </div>
          <div class="dates-details">
            <div v-if="education.school" class="sub-title" style="font-weight: 600;">{{ education.school }}</div>
            <div v-if="education.degree" class="sub-title">{{ education.degree }}</div>
            <div v-if="education.description && containsTextOutsideHtml(education.description)" class="descriptions" v-html="education.description"></div>
          </div>
        </div>
        <div v-if="cvData.skills" class="section-name" style="padding-top: 5px;">{{ t('Skills') }}</div>
        <div class="skills-container">
          <div v-for="(skill, index) in cvData.skills" :key="skill.id" class="skill-item">
            <div v-if="skill.name" class="sub-title">
              <span class="skill-name">{{ skill.name }}</span><span v-if="skill.level"> - <span class="skill-level">{{ skill.level }}</span></span>
            </div>
            <span v-if="cvData.skills && index !== cvData.skills.length - 1" class="slash-separator"> | </span>
          </div>
        </div>

        <div v-if="cvData.languages" class="section-name" style="padding-top: 5px;">{{ t('Languages') }}</div>
        <div class="skills-container">
          <div v-for="(language, index) in cvData.languages" :key="language.id" class="skill-item">
            <div v-if="language.name" class="sub-title">
              <span class="skill-name">{{ language.name }}</span><span v-if="language.level"> - <span class="skill-level">{{ language.level }}</span></span>
            </div>
            <span v-if="cvData.languages && index !== cvData.languages.length - 1" class="slash-separator"> | </span>
          </div>
        </div>

        <div v-if="cvData.projects && cvData.projects.length > 0" class="section-name" style="padding-top: 5px;">{{ t("Projects") }}</div>
        <div v-for="(project, index) in cvData.projects" :key="project.id">
          <div class="sub-title" style="font-weight: 600;">{{ project.title }}</div>
          <div class="vertical-adjuster">
            <div v-if="project.startDate" class="sub-title"> | {{ project.startDate }} - {{ project.endDate }}</div>
            <div v-if="project.description && containsTextOutsideHtml(project.description)" class="descriptions"
                 v-html="project.description">
            </div>
          </div>
          <div v-if=" cvData.projects && index !== cvData.projects.length - 1" class="padding-adder"></div>
        </div>
        <div v-if="cvData.certifications && cvData.certifications.length > 0" class="section-name" style="padding-top: 5px;">{{ t("Certifications") }}</div>
        <div v-for="(certification, index) in cvData.certifications" :key="certification.id">
          <div class="horizontal-adjuster">
            <div class="sub-title" style="font-weight: 600;">{{ certification.course }}</div>
            <div v-if="certification.institute" class="sub-title">{{ certification.institute }}</div>
            <div v-if="certification.startDate" class="sub-title">{{ certification.startDate }} - {{ certification.endDate }}</div>
          </div>
          <div v-if=" cvData.certifications && index !== cvData.certifications.length - 1" class="padding-adder"></div>
        </div>
        <div v-if="cvData.hobbies && cvData.hobbies.length > 0" class="section-name" style="padding-top: 5px;">{{ t('Hobbies') }}</div>
        <div class="skills-container">
          <div v-for="(hobby, index) in cvData.hobbies" :key="hobby.id" class="skill-item">
            <div v-if="hobby.description && containsTextOutsideHtml(hobby.description)" class="descriptions"
                 v-html="hobby.description">
            </div>
            <span v-if="cvData.hobbies && index !== cvData.hobbies.length - 1" class="slash-separator"> | </span>
          </div>
        </div>
        <div v-if="cvData.honors && cvData.honors.length > 0" class="section-name" style="padding-top: 5px;">{{ t('Honors') }}</div>
        <div class="skills-container">
          <div v-for="(honor, index) in cvData.honors" :key="honor.id" class="skill-item">
            <div v-if="honor.description && containsTextOutsideHtml(honor.description)" class="descriptions"
                 v-html="honor.description">
            </div>
            <span v-if="cvData.honors && index !== cvData.honors.length - 1" class="slash-separator"> | </span>
          </div>
        </div>
        <div v-if="cvData.publications && cvData.publications.length > 0" class="section-name" style="padding-top: 5px;">{{ t('Publications') }}</div>
        <div class="skills-container">
          <div v-for="(publication, index) in cvData.publications" :key="publication.id" class="skill-item">
            <div v-if="publication.description && containsTextOutsideHtml(publication.description)" class="descriptions"
                 v-html="publication.description">
            </div>
            <span v-if="cvData.publications && index !== cvData.publications.length - 1" class="slash-separator"> | </span>
          </div>
        </div>
        <div v-if="cvData.exhibitions && cvData.exhibitions.length > 0" class="section-name" style="padding-top: 5px;">{{ t('Exhibitions') }}</div>
        <div class="skills-container">
          <div v-for="(exhibition, index) in cvData.exhibitions" :key="exhibition.id" class="skill-item">
            <div v-if="exhibition.description && containsTextOutsideHtml(exhibition.description)" class="descriptions"
                 v-html="exhibition.description">
            </div>
            <span v-if="cvData.exhibitions && index !== cvData.exhibitions.length - 1" class="slash-separator"> | </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
:root {
  --global-font-size: 10px;
}

.resume {
  width: 595px;
  max-width: 98%;
  padding-right: 1%;
  padding-left: 1%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  text-align: start !important;
}

.center-container {
  display: flex;
  flex-direction: column;
  width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 10px;
}

.person-name {
  font-size: calc(var(--global-font-size) + 20px);
  padding-top: 20px;
  font-weight: 700;
  color: var(--resume-color);
}

.job-title {
  font-size: calc(var(--global-font-size) + 10px);
  padding-bottom: 10px;
  color: var(--resume-color);
}

.line {
  height: 2px;
  background-color: var(--resume-color);
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
}

.descriptions {
  font-size: calc(var(--global-font-size) + 2px);
  color: black;
  padding: 0;
  margin: 0;
  line-height: 1.2;
}

.descriptions * {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.section-name {
  display: flex;
  align-items: center;
  font-size: calc(var(--global-font-size) + 10px);
  font-weight: bold;
  color: var(--resume-color);
  padding-bottom: 5px;
}

.section-name::after {
  content: "";
  flex-grow: 1;
  height: 2px;
  background-color: var(--resume-color);
  margin-left: 3px;
}
.sub-title {
  font-size: calc(var(--global-font-size) + 4px);
  color: black;
}
.horizontal-adjuster {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-left: 5px;
}

.horizontal-adjuster-no-gap {
  display: flex;
  flex-direction: row;
  padding-left: 5px;
}

.skills, .hobbies{
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  flex-wrap: wrap;
  row-gap: 5px;
}

.vertical-adjuster {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dates-container {
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  align-items: stretch;
  margin-bottom: 5px;
}
.section-dates {
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: auto;
}

.section-dates .date {
  position: relative;
  padding-left:calc(var(--global-font-size) + 2px);
}

.section-dates .date::before {
  content: "•";
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  color: var(--resume-color);
}

.section-dates::after {
  content: "";
  position: absolute;
  width: 5px;
  background-color: var(--resume-color);
  top:  20px;
  bottom:  18px;
  left: 1px;
  z-index: -1;
}

.dates-details {
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
}
.dates-details .sub-title {
  margin: 0;
}
.date{
  font-size: calc(var(--global-font-size) + 2px);
  color: black;
  padding-top: 2px;
  padding-bottom: 2px;
}
.skills-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 5px;
}

.skill-item {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.slash-separator {
  padding-left: 5px;
  padding-right: 10px;
}
.skill-name {
  font-weight: 600;
}

.skill-level {
  font-weight: normal;
}
.padding-adder{
    padding-bottom: 8px;
}

  </style>

