<script setup lang="ts">
import { ref } from "vue";

import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import AuthModal from "@/components/AuthModal.vue";
import SessionExpired from "@/components/SessionExpired.vue";

import HomeCards from "./HomeCards.vue";
import HomeFacts from "./HomeFacts.vue";
import HomeFaq from "./HomeFaq.vue";
import HomeFeedback from "./HomeFeedbacks.vue";
import HomeImage from "./HomeImage.vue";
import HomeInfo from "./HomeInfo.vue";

const { t } = useI18n();

const route = useRoute();

const modalAuthenticationVisible = ref(false);
const authenticationNavigate = ref("");

const showSessionExpiredModal = ref(route.query.sessionExpired === "true");

const faqsData = [
  { questionKey: t("faqs.serviceOffered"), answerKey: t("faqs.serviceOfferedAnswer"), showAnswer: false },
  { questionKey: t("faqs.howToUpgrade"), answerKey: t("faqs.howToUpgradeAnswer"), showAnswer: false },
  { questionKey: t("faqs.paymentMethods"), answerKey: t("faqs.paymentMethodsAnswer"), showAnswer: false },
  { questionKey: t("faqs.cancelSubscription"), answerKey: t("faqs.cancelSubscriptionAnswer"), showAnswer: false },
  { questionKey: t("faqs.premiumTemplates"), answerKey: t("faqs.premiumTemplatesAnswer"), showAnswer: false },
  { questionKey: t("faqs.refundPolicy"), answerKey: t("faqs.refundPolicyAnswer"), showAnswer: false },
  { questionKey: t("faqs.techSupport"), answerKey: t("faqs.techSupportAnswer"), showAnswer: false },
  { questionKey: t("faqs.useMultipleDevices"), answerKey: t("faqs.useMultipleDevicesAnswer"), showAnswer: false },
  { questionKey: t("faqs.dataPrivacy"), answerKey: t("faqs.dataPrivacyAnswer"), showAnswer: false },

];

const closeModal = () => {
  showSessionExpiredModal.value = false;
};

const login = () => {
  modalAuthenticationVisible.value = true;
};

</script>

<template>
  <div class="container">
    <SessionExpired v-if="showSessionExpiredModal" @closeModal="closeModal" @login="login"/>
    <AuthModal
      :isVisible="modalAuthenticationVisible"
      :navigateTo="authenticationNavigate"
      @update:visible="modalAuthenticationVisible = $event"
    />
    <div>
      <HomeImage />
      <HomeCards/>
      <HomeInfo/>
      <HomeFeedback/>
      <HomeFacts/>
      <HomeFaq :faqs="faqsData"/>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

</style>