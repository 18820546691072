<script setup lang="ts">
import { computed, ref, watch } from "vue";

import DOMPurify from "dompurify";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";
import draggable from "vuedraggable";
import { useStore } from "vuex";

import { useAuth } from "@/auth/useAuth";
import type { Reference } from "@/composables/types";
import { modifyLibraryEntry } from "@/services/libraryService";

import { initialStateReference } from "../../../utils/initialSectionStates";
const { t } = useI18n();

const props = defineProps<{
  cvId: string,
  evaluated: boolean,
}>();

const { user } = useAuth();

const store = useStore();
const showAdd = ref(true);
const isAdding = ref(false);

watch(
  () => store.state.cvData,
  async (newcvData) => {
    try {
      await modifyLibraryEntry(props.cvId, user.value.details?.uid || "", newcvData);

    } catch (error) {
      console.error("Failed to update library entry:", error);
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

const addReference = ref<Reference>(initialStateReference(uuidv4()));

const references = computed({
  get: () => store.state.cvData.references,
  set: (value) => store.commit("updateCvSection", { section: "references", data: value }),
});

const updateField = (fieldName: keyof Reference, event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  if (fieldName === "name" || fieldName === "company" || fieldName === "phone" || fieldName === "email" ) {
    addReference.value[fieldName] = DOMPurify.sanitize(value);
  }
  if(!isAdding.value){
    store.dispatch("updateCvItem", {
      section: "references",
      itemId: addReference.value.id,
      newData: addReference.value,
    });
  }
};

</script>

<template>
  <div class="reference-section">
    <div class="score-container">
      <div class="reference-header">
        <h2>{{ t('ReferenceDetailsSection.References') }}</h2>
      </div>
    </div>

    <div class="button-wrapper">
      <button
        v-if="showAdd"
        class="add-button"
        @click="
          () => {
            isAdding = true;
            showAdd = false;
          }
        "
      >
        {{ t('ReferenceDetailsSection.AddAReference') }} <font-awesome-icon icon="plus" />
      </button>
      <div v-if="!showAdd">
        <div class="input-row">
          <div class="input-wrapper">
            <label for="referenceName">{{ t('ReferenceDetailsSection.FullName') }}</label>
            <input
              id="referenceName"
              type="text"
              :placeholder="t('ReferenceDetailsSection.EnterFullName')"
              :value="addReference.name"
              @blur="updateField('name', $event)"
            />
          </div>

          <div class="input-wrapper">
            <label for="referenceCompany">{{ t('ReferenceDetailsSection.Company') }}</label>
            <input
              id="referenceCompany"
              type="text"
              :placeholder="t('ReferenceDetailsSection.EnterCompanyName')"
              :value="addReference.company"
              @blur="updateField('company', $event)"
            />
          </div>
        </div>
        <div class="input-row">
          <div class="input-wrapper">
            <label for="referencePhone">{{ t('ReferenceDetailsSection.PhoneNumber') }}</label>
            <input
              id="referencePhone"
              type="text"
              :placeholder="t('ReferenceDetailsSection.PhoneNumber')"
              :value="addReference.phone"
              @blur="updateField('phone', $event)"
            />
          </div>
          <div class="input-wrapper">
            <label for="referenceEmail">{{ t('ReferenceDetailsSection.EmailAddress') }}</label>
            <input
              id="referenceEmail"
              type="text"
              :placeholder="t('ReferenceDetailsSection.EnterEmailAddress')"
              :value="addReference.email"
              @blur="updateField('email', $event)"
            />
          </div>
        </div>
        <button
          type="button"
          class="action-button"
          @click="
            () => {
              if (isAdding) {
                store.dispatch('addCvItem', {
                  section: 'references',
                  item: { ...addReference, id: uuidv4() },
                });
                isAdding = false;
              }
              addReference = initialStateReference(uuidv4());
              showAdd = true;
            }
          "
        >
          {{ isAdding ? t('Add') : t('Save') }}
        </button>
        <button
          v-if="isAdding"
          type="button"
          class="action-button"
          @click="
            () => {
              showAdd = true;
              isAdding = false;
              addReference = initialStateReference(uuidv4());
            }
          "
        >
          {{ t('Discard') }}
        </button>
      </div>
    </div>
    <div>
      <draggable
        v-if="showAdd"
        v-model="references"
        itemKey="id"
        class="draggable-container"
        tag="div"
      >
        <template #item="{ element, index }">
          <div :key="element.id" class="reference-item">
            <svg class="drag-icon" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0V0z" fill="none"></path>
              <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
            </svg>
            <div class="reference-content">
              <label class="reference-name">{{  element.name  }}</label>
              <label class="reference-company">{{ element.company }}</label>
            </div>
            <div class="reference-actions">
              <font-awesome-icon
                icon="edit"
                class="reference-action-icon"
                @click="
                  () => {
                    showAdd = false;
                    addReference = element;
                  }
                "
              />
              <font-awesome-icon
                icon="trash"
                class="reference-action-icon"
                @click="
                  store.dispatch('removeCvItem', {
                    section: 'references',
                    index,
                  })
                "
              />
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<style scoped>
.reference-header {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.score-container {
  display: flex;
  flex-direction: row;
}

.drag-icon {
  color: #c2c2c2;
  fill: #c2c2c2;
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.reference-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.reference-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  border-radius: 3%;
  background-color: #e1dfff;
  min-height: 2.6rem;
  padding: 10px;
}

.reference-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  gap: 15px;
}

.reference-action-icon {
  color: #909090;
}

.reference-name {
  margin-left: 20px;
}

.reference-company {
  color: #909090;
  margin-right: 20px;
}

.button-wrapper {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 25px;
}

.add-button{
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  gap: 10px;
}

.button-wrapper button:hover {
  background-color: rgb(95, 82, 210, 0.7);
}

.button-wrapper button:focus {
  outline: none;
}

.reference-section {
  width: 100%;
  margin: auto;
  background: #fff;
  padding: 20px;
  align-items: flex-start;
}

.reference-section h2 {
  text-align: start;
}

.reference-section p {
  text-align: start;
  color: #7d7d7d;
}

.input-row {
  display: flex;
  gap: 20px;
  margin-bottom: 25px;
  margin-top: 45px;
  align-items: flex-start;
}

.input-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-wrapper label {
  display: block;
  margin-bottom: 5px;
  color: #909090;
  font-weight: bold;
}

.input-wrapper input[type="text"],
.input-wrapper input[type="email"],
.input-wrapper input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.input-wrapper input[type="date"] {
  width: 50%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.action-button {
  cursor: pointer;
  width: 120px;
  height: 36px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: #dedede;
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 24px;
  outline: none;
  margin-right: 10px;
  margin-top: 15px;
}

.draggable-container {
  margin-top: 25px;
}

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
</style>

