<script setup lang="ts">
import { onMounted, ref, watch, onUnmounted, nextTick } from "vue";

import { useI18n } from "vue-i18n";

import type { CvData } from "@/composables/types";
import type { HTMLElementWithVNode, ForbiddenZone } from "@/views/cv-creation-view/const";
import { adjustLayout } from "@/views/cv-creation-view/utils/adjustPreview";
import { containsTextOutsideHtml } from "@/views/cv-creation-view/utils/text";

const { cvData, adjust = false } = defineProps<{
  cvData: CvData,
  adjust?: boolean
}>();

const { t } = useI18n();

const divManipulatorRef = ref<HTMLElementWithVNode | null>(null);
const forbiddenZones = ref<ForbiddenZone[]>([]);

const updateForbiddenZones = () => {
  const children = divManipulatorRef.value ? Array.from(divManipulatorRef.value.children) as HTMLElement[] : [];
  if (children.length > 0) {
    const rectForFirst = children[0].getBoundingClientRect();

    forbiddenZones.value = [
      { start: rectForFirst.y + 740, end: rectForFirst.y + 840 },
      { start: rectForFirst.y + 1590, end: rectForFirst.y + 1680 },
      { start: rectForFirst.y + 2350, end: rectForFirst.y + 2490 },
      { start: rectForFirst.y + 3150, end: rectForFirst.y + 3290 },
    ];
  }
};

const updateLeftSectionHeight = () => {
  const leftSection = divManipulatorRef.value?.querySelector(".left-section") as HTMLElement | null;
  if (leftSection) {
    const contentHeight = leftSection.scrollHeight;
    const height196vh = window.innerHeight * 1.96;
    const height392vh = window.innerHeight * 3.92;
    const height588vh = window.innerHeight * 5.88;
    const height784vh = window.innerHeight * 7.84;

    if (contentHeight <= height196vh) {
      leftSection.style.minHeight = "196vh";
    } else if (contentHeight > height196vh && contentHeight <= height392vh) {
      leftSection.style.minHeight = "392vh";
    } else if (contentHeight > height392vh && contentHeight <= height588vh) {
      leftSection.style.minHeight = "588vh";
    } else if (contentHeight > height588vh && contentHeight <= height784vh) {
      leftSection.style.minHeight = "784vh";
    } else {
      leftSection.style.minHeight = `${contentHeight}px`;
    }
  }
};
const isColorDark = (color: string) => {
  let r, g, b;
  if (color.startsWith("#")) {
    const hex = color.slice(1);
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else {
    const rgb = color.match(/\d+/g);
    [r, g, b] = rgb ? rgb.map(Number) : [0, 0, 0];
  }
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness < 128;
};

const textColor = ref("");

watch(() => cvData.resumeColor, (newColor) => {
  textColor.value = isColorDark(newColor) ? "white" : "black";
}, { immediate: true });

watch(() => cvData, () => {

  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }
}, { deep: true, immediate: true });

watch(cvData, (newValue) => {
  const fontSize = `${newValue.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
});

onMounted(async () => {
  await nextTick();
  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }

  const fontSize = `${cvData.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
  window.addEventListener("resize", () => {
    updateForbiddenZones();
    updateLeftSectionHeight();
    if(!adjust)
    {
      updateLeftSectionHeight();
      adjustLayout(forbiddenZones.value, divManipulatorRef);
    }
  });
});

onUnmounted(() => {
  window.removeEventListener("resize", () => {
    updateForbiddenZones();
    updateLeftSectionHeight();
    if(!adjust)
    {
      updateLeftSectionHeight();
      adjustLayout(forbiddenZones.value, divManipulatorRef);
    }
  });
});
</script>

<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
  <div ref="divManipulatorRef" class="div-manipulator" :style="{ fontFamily: cvData.resumeFontFamily }">
    <section class="resume">
      <div class="center-container">
        <div v-if="cvData.contact?.firstName || cvData.contact?.lastName" class="person-name"
             :style="{ color: cvData.resumeColor }">
          {{ cvData.contact?.firstName }} {{ cvData.contact?.lastName }}
        </div>
        <div v-if="cvData.contact?.jobTitle" class="job-title" :style="{ color: cvData.resumeColor }">{{ cvData.contact?.jobTitle }}</div>

        <div class="personal-details"  :style="{ border: `1px solid ${cvData.resumeColor}` }">
          <div v-if="cvData.contact?.phone" class="personal-details-detail">{{ cvData.contact?.phone }}</div>
          <div v-if="cvData.contact?.email" class="personal-details-detail">{{ cvData.contact?.email }}</div>
          <div v-if="cvData.contact?.city" class="personal-details-detail">{{ cvData.contact?.city }}</div>
          <div v-if="cvData.contact?.country" class="personal-details-detail">{{ cvData.contact?.country }}</div>
          <div v-if="cvData.contact?.address" class="personal-details-detail">{{ cvData.contact?.address }}</div>
          <div v-if="cvData.contact?.birthDate" class="personal-details-detail">{{ cvData.contact?.birthDate }}</div>
        </div>

        <div v-if="cvData.contact?.description && containsTextOutsideHtml(cvData.contact.description)" class="section-name" :style="{ color: cvData.resumeColor }">{{ t('Summary') }}</div>
        <div v-if="cvData.contact?.description && containsTextOutsideHtml(cvData.contact.description)" class="summary-handler"
             v-html="cvData.contact.description">
        </div>

        <div class="section-name" :style="{ color: cvData.resumeColor }">{{ t('Skills') }}</div>
        <div class="section-content-rows" :style="{ backgroundColor: cvData.resumeColor, color: textColor }">
          <template v-for="(skill, index) in cvData.skills?.length ? cvData.skills : []" :key="skill.id">
            <span class="skill">{{ skill.name }}</span>
            <span v-if="index < (cvData.skills?.length || 0) - 1" class="skill"> &nbsp;• </span>
          </template>
        </div>

        <div v-if="cvData.experiences && cvData.experiences.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Experiences') }}</div>
        <div v-if="cvData.experiences && cvData.experiences.length > 0" class="section-content-rows-no-background">
          <div v-for="experience in cvData.experiences" :key="experience.id" class="experience">
            <div class="dates-title">
              <div class="main-title">{{ experience.title }}</div>
              <div v-if="experience.startDate" class="sub-title" >{{ experience.startDate }} - {{ experience.endDate }}</div>
            </div>
            <div v-if="experience.company" class="sub-title">{{ experience.company }}</div>
            <div v-if="experience.description && containsTextOutsideHtml(experience.description)" class="description-handler"
                 v-html="experience.description"></div>
          </div>
        </div>

        <div v-if="cvData.educations && cvData.educations.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Educations') }}</div>
        <div v-if="cvData.educations && cvData.educations.length > 0" class="section-content-rows-no-background">
          <div v-for="education in cvData.educations" :key="education.id" class="education">
            <div class="dates-title">
              <div class="main-title">{{ education.degree }}</div>
              <div v-if="education.startDate" class="sub-title">{{ education.startDate }} - {{ education.endDate }}</div>
            </div>
            <div class="dates-title">
              <div v-if="education.school" class="sub-title">{{ education.school }}</div>
              <div v-if="education.location" class="sub-title">{{ education.location }}</div>
            </div>
            <div v-if="education.description && containsTextOutsideHtml(education.description)" class="description-handler" v-html="education.description"></div>
          </div>
        </div>

        <div v-if="cvData.languages" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Languages') }}</div>
        <div v-if="cvData.languages" class="languages" style="background-color: white; color: black;">
          <div v-for="language in cvData.languages" :key="language.id" >
            <div class="language">{{ language.name }}</div>
          </div>
        </div>

        <div v-if="cvData.projects && cvData.projects.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Projects') }}</div>
        <div v-if="cvData.projects && cvData.projects.length > 0" class="section-content-rows-no-background">
          <div v-for="project in cvData.projects" :key="project.id" class="projects">
            <div class="dates-title">
              <div class="main-title">{{ project.title }}</div>
              <div v-if="project.startDate" class="sub-title">{{ project.startDate }} - {{ project.endDate }}</div>
            </div>
            <div v-if="project.description && containsTextOutsideHtml(project.description)" class="description-handler" v-html="project.description"></div>
          </div>
        </div>

        <div v-if="cvData.certifications && cvData.certifications.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Certifications') }}</div>
        <div v-if="cvData.certifications && cvData.certifications.length > 0" class="section-content-rows-no-background">
          <div v-for="certification in cvData.certifications" :key="certification.id" class="certifications">
            <div class="dates-title">
              <div class="main-title">{{ certification.course }}</div>
              <div v-if="certification.startDate" class="sub-title">{{ certification.startDate }} - {{ certification.endDate }}</div>
            </div>
            <div class="sub-title">{{ certification.institute }}</div>
          </div>
        </div>

        <div v-if="cvData.references && cvData.references.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('References') }}</div>
        <div v-if="cvData.references && cvData.references.length > 0" class="section-content-rows-no-background">
          <div v-for="reference in cvData.references" :key="reference.id" class="references">
            <div v-if="reference.name" class="sub-title">{{ reference.name }}</div>
            <div v-if="reference.company" class="sub-title">{{ reference.company }}</div>
            <div v-if="reference.phone"  class="sub-title">{{ reference.phone }}</div>
            <div v-if="reference.email"  class="sub-title">{{ reference.email }}</div>
          </div>
        </div>

        <div v-if="cvData.publications && cvData.publications.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Publications') }}</div>
        <div v-if="cvData.publications && cvData.publications.length > 0" class="section-content-rows-no-background">
          <div v-for="publication in cvData.publications" :key="publication.id" class="publications">
            <div v-if="publication.description && containsTextOutsideHtml(publication.description)" class="sub-title" v-html="publication.description"></div>
          </div>
        </div>

        <div v-if="cvData.hobbies && cvData.hobbies.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Hobbies') }}</div>
        <div v-if="cvData.hobbies && cvData.hobbies.length > 0" class="section-content-rows-no-background">
          <div v-for="hobbie in cvData.hobbies" :key="hobbie.id" class="hobbies">
            <div v-if="hobbie.description && containsTextOutsideHtml(hobbie.description)" class="sub-title" v-html="hobbie.description"></div>
          </div>
        </div>

        <div v-if="cvData.honors && cvData.honors.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Honors') }}</div>
        <div v-if="cvData.honors && cvData.honors.length > 0" class="section-content-rows-no-background">
          <div v-for="honor in cvData.honors" :key="honor.id" class="honors">
            <div v-if="honor.description && containsTextOutsideHtml(honor.description)" class="sub-title" v-html="honor.description"></div>
          </div>
        </div>

        <div v-if="cvData.voluntaries && cvData.voluntaries.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Voluntaries') }}</div>
        <div v-if="cvData.voluntaries && cvData.voluntaries.length > 0" class="section-content-rows-no-background">
          <div v-for="voluntary in cvData.voluntaries" :key="voluntary.id" class="voluntaries">
            <div v-if="voluntary.description && containsTextOutsideHtml(voluntary.description)" class="sub-title" v-html="voluntary.description"></div>
          </div>
        </div>

        <div v-if="cvData.exhibitions && cvData.exhibitions.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Exhibitions') }}</div>
        <div v-if="cvData.exhibitions && cvData.exhibitions.length > 0" class="section-content-rows-no-background">
          <div v-for="exhibition in cvData.exhibitions" :key="exhibition.id" class="exhibitions">
            <div v-if="exhibition.description && containsTextOutsideHtml(exhibition.description)" class="sub-title" v-html="exhibition.description"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>

:root {
  --global-font-size: 10px;
}

.resume {
  width: 595px;
  max-width: 100%;
  max-height: 2524px;
  background: var(--white);
  display: flex;
  text-align: start !important;
}
.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
}
.person-name {
  font-weight: 700;
  font-size: calc(var(--global-font-size) + 16px);
  font-style: normal;
  color: black;
  font-kerning: none;
  text-decoration: none;
  align-self: center;
  text-align: center;
}
.job-title {
  font-size: calc(var(--global-font-size) + 10px);
  font-weight: 400;
  font-style: normal;
  color: black;
  font-kerning: none;
  text-decoration: none;
}
.personal-details {
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  row-gap: 5px;
  max-width: 575px;
  color: black;;
  border: 1px solid black;
  font-size: calc(var(--global-font-size) + 4px);
  font-weight: 700;
  flex-wrap: wrap;
  width: 100%;
}
.personal-details > *:not(:last-child)::after {
  content: "|";
  margin-left: 5px;
}

.personal-details-detail {
  padding: 0 2px;
}

.personal-details-separator {
  margin: 0 2px;
}

.section-name{
  padding-top: 5px;
  align-self: flex-start;
  padding-bottom: 2px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 5px;
  font-size: calc(var(--global-font-size) + 4px);
  color: black;
  width: 100%;
  font-weight: 700;
}
.section-content-rows {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
  padding: 5px 0;
  align-self: flex-start;
  width: 100%;
  background-color: black;
  color: white;
}
.languages {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-self: flex-start;
  padding-right: 5px;
  width: 100%;
  background-color: white;
  color: black;
}
.skill, .language {
  font-weight: 500;
  font-size: calc(var(--global-font-size) + 2px);
}

.language{
  padding-right: 5px;
}
.experience, .education, .projects, .certifications, .hobbies, .honors, .voluntaries, .publications, .exhibitions {
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  position: relative;
  width: 100%;
}
.experience:last-child, .education:last-child {
  padding-bottom: 0;
}
.references {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.main-title {
  font-size: calc(var(--global-font-size) + 4px);
  font-weight: 600;
  font-style: normal;
  color: black;
  font-kerning: none;
  text-decoration: none;
  line-height: 18px;
}
.sub-title {
  font-size: calc(var(--global-font-size) + 2px);
  font-style: normal;
  color: black;
  font-kerning: none;
  text-decoration: none;
  padding-bottom: 2px;
}
.section-content-rows-no-background {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
}
.dates {
  display: flex;
  justify-content: end;
}
.dates-title{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 2px;
  width: 100%;
}
.description-handler {
  width: 100%;
  font-size: calc(var(--global-font-size) + 2px);
}
.summary-handler{
  width: 100%;
  font-size: calc(var(--global-font-size) + 2px);
  column-gap: 2px;
  row-gap: 2px;
}
  </style>

