<script setup lang="ts">
import { ref, watch } from "vue";

import DOMPurify from "dompurify";
import { useI18n } from "vue-i18n";

import { useAuth } from "@/auth/useAuth";
import { modifyUserInfo } from "@/services/userService";

import ChangePasswordPopup from "./components/ChangePasswordPopup.vue";

const { t } = useI18n();

const { user } = useAuth();

const firstName = ref<string | undefined>();
const lastName = ref<string | undefined>();
const email = ref<string | undefined>();
const showChangePassword = ref(false);
const passwordUpdated = ref(false);

watch(
  () => user.value.details,
  (details) => {
    if (details) {
      firstName.value = details.firstName;
      lastName.value = details.lastName;
      email.value = details.email;
    }
  },
  { immediate: true },
);

const showChangePasswordPopup = () => {
  if(user.value.authUser?.providerData[0].providerId !== "google.com"){
    showChangePassword.value = true;
  }
};

const closeChangePasswordPopup = () => {
  showChangePassword.value = false;
};

const save = async () => {
  await modifyUserInfo(user.value.details?.uid || "", { firstName: DOMPurify.sanitize(firstName.value || ""), lastName: DOMPurify.sanitize(lastName.value || "") });
  passwordUpdated.value = true;
  setTimeout(() => {
    passwordUpdated.value = false;
  }, 3000);
};
</script>

<template>
  <div class="account-settings">
    <span class="account-text">{{ t("Account.AccountSettings") }}</span>
    <div class="account-settings-div">
      <div class="first-last-div">
        <div class="input-div">
          <label class="input-label">{{ t("Account.FirstName") }}</label>
          <input
            id="firstName"
            v-model="firstName"
            type="text"
            :placeholder="t('Account.FirstName')"
            class="input-class"
          />
        </div>

        <div class="input-div">
          <label class="input-label">{{ t("Account.LastName") }}</label>
          <input
            id="lastName"
            v-model="lastName"
            type="text"
            :placeholder="t('Account.LastName')"
            class="input-class"
          />
        </div>
      </div>
      <div class="email-div">
        <label class="input-label">{{ t("Account.Email") }}</label>
        <input
          id="email"
          type="text"
          :placeholder="t('Account.Email')"
          :value="email"
          class="input-class"
          disabled
        />
      </div>
      <div class="button-icon-container">
        <button class="change-password-button" @click="showChangePasswordPopup">{{ t("Account.ChangePassword") }}</button>
        <button class="save-button" @click="save">{{ t("Save") }}</button>
        <svg v-if="passwordUpdated" class="success-icon-pass" viewBox="0 0 24 24">
          <path d="M9 16.2l-4.2-4.2L3 13.8 9 19l12-12-1.4-1.4L9 16.2z" />
        </svg>
      </div>
    </div>

    <ChangePasswordPopup :show="showChangePassword" @close="closeChangePasswordPopup" />
  </div>
</template>

<style scoped>

.error {
  color: red;
}

.success {
  color: green;
}

.save-button{
    cursor: pointer;
  width: 120px;
  height: 36px;
  padding: 0px 8px;
  border: 1px solid #5438da;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0);
  color: #5438da;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 24px;
  outline: none;
  align-self: center;
  margin-top: 20px;
}

.change-password-button{
    cursor: pointer;
  width: 186px;
  height: 36px;
  padding: 0px 8px;
  border: 1px solid #909090;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: rgba(255,255,255,0.07);
  color: #909090;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 16px;
  outline: none;
  align-self: center;
  margin-top: 40px;
}

.input-div{
    display: flex;
    flex-direction: column;
}

.email-div{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.first-last-div{
    display: flex;
    flex-direction: row;
    gap: 80px;
}

.input-class{
    width: 313px;
  height: 43px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 24px;
  outline: none;
}

.input-label{
    color: #909090;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 21px;
  align-self: flex-start;
  margin-bottom: 7px;
}

.account-settings-div{
    display: flex;
    flex-direction: column;
    width: 957px;
  height: 450px;
  background-color: #f0f0f0;
  border-radius: 26px;
  align-items: flex-start;
  padding: 50px;
}

.account-settings{
    width: 65%;
    margin-top: 100px;
    align-items: center;
}

.account-text{
    color: #030303;
    font-size: 24px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 31px;
    align-self: flex-start;
    display: flex;
    margin-bottom: 50px;
}
.success-icon-pass {
  margin-top: 20px;
  color: green;
  fill: green;
  font-size: 30px;
  display: inline-block;
  align-self: center;
  margin: 5px;
  height: 40px;
  width: 40px;
}

.button-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  align-self: center;
}
</style>