<script setup lang="ts">
import { onMounted, ref, watch, onUnmounted, nextTick } from "vue";

import { useI18n } from "vue-i18n";

import type { CvData } from "@/composables/types";
import type { HTMLElementWithVNode, ForbiddenZone } from "@/views/cv-creation-view/const";
import { adjustLayout } from "@/views/cv-creation-view/utils/adjustPreview";
import { containsTextOutsideHtml } from "@/views/cv-creation-view/utils/text";

const { cvData, adjust = false } = defineProps<{
  cvData: CvData,
  adjust?: boolean
}>();

const { t } = useI18n();

const divManipulatorRef = ref<HTMLElementWithVNode | null>(null);
const forbiddenZones = ref<ForbiddenZone[]>([]);

const updateForbiddenZones = () => {
  const children = divManipulatorRef.value ? Array.from(divManipulatorRef.value.children) as HTMLElement[] : [];
  if (children.length > 0) {
    const rectForFirst = children[0].getBoundingClientRect();

    forbiddenZones.value = [
      { start: rectForFirst.y + 740, end: rectForFirst.y + 840 },
      { start: rectForFirst.y + 1590, end: rectForFirst.y + 1680 },
      { start: rectForFirst.y + 2350, end: rectForFirst.y + 2490 },
      { start: rectForFirst.y + 3150, end: rectForFirst.y + 3290 },
    ];
  }
};

const updateLeftSectionHeight = () => {
  const leftSection = divManipulatorRef.value?.querySelector(".left-section") as HTMLElement | null;
  if (leftSection) {
    const contentHeight = leftSection.scrollHeight;
    const height196vh = window.innerHeight * 1.96;
    const height392vh = window.innerHeight * 3.92;
    const height588vh = window.innerHeight * 5.88;
    const height784vh = window.innerHeight * 7.84;

    if (contentHeight <= height196vh) {
      leftSection.style.minHeight = "196vh";
    } else if (contentHeight > height196vh && contentHeight <= height392vh) {
      leftSection.style.minHeight = "392vh";
    } else if (contentHeight > height392vh && contentHeight <= height588vh) {
      leftSection.style.minHeight = "588vh";
    } else if (contentHeight > height588vh && contentHeight <= height784vh) {
      leftSection.style.minHeight = "784vh";
    } else {
      leftSection.style.minHeight = `${contentHeight}px`;
    }
  }
};

watch(() => cvData, () => {

  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }
}, { deep: true, immediate: true });

watch(cvData, (newValue) => {
  const fontSize = `${newValue.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
});

onMounted(async () => {
  await nextTick();
  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }

  const fontSize = `${cvData.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
  window.addEventListener("resize", () => {
    updateForbiddenZones();
    updateLeftSectionHeight();
    if(!adjust)
    {
      updateLeftSectionHeight();
      adjustLayout(forbiddenZones.value, divManipulatorRef);
    }
  });
});

onUnmounted(() => {
  window.removeEventListener("resize", () => {
    updateForbiddenZones();
    updateLeftSectionHeight();
    if(!adjust)
    {
      updateLeftSectionHeight();
      adjustLayout(forbiddenZones.value, divManipulatorRef);
    }
  });
});
</script>

<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
  <div ref="divManipulatorRef" class="div-manipulator" :style="{ fontFamily: cvData.resumeFontFamily }">
    <section class="resume">
      <div class="left-section" :style="{backgroundColor: cvData.resumeColor}">
        <div class="contact-div">
          <div class="contact-basics-div">
            <span class="fullname-text-contact">{{ cvData.contact?.firstName }} {{ cvData.contact?.lastName }}</span>
            <p v-if="cvData.contact?.phone" class="name-text-contact"><i class="fas fa-phone"></i> {{ cvData.contact?.phone }}</p>
            <p v-if="cvData.contact?.email" class="name-text-contact"><i class="fas fa-envelope"></i> {{ cvData.contact?.email }}</p>
            <p v-if="cvData.contact?.address" class="name-text-contact"><i class="fas fa-map-marker-alt"></i> {{ cvData.contact?.address }}</p>
          </div>
        </div>
        <div v-if="cvData.skills && cvData.skills.length > 0" class="skills">
          <span class="section-title-text">{{ t('Skill') }}</span>
          <div class="custom-line-left"></div>
          <div v-for="skill in cvData.skills" :key="skill.id">
            <div class="organization-div-side">
              <div class="skill-name-div">
                <span class="organization-text">{{ skill?.name }}</span>
              </div>
              <div class="skill-level-div">
                <span v-if="skill.level && cvData.showSkillLevels" class="organization-text">{{ skill?.level }}</span>
              </div>

            </div>
          </div>
        </div>
        <div v-if="cvData.languages && cvData.languages.length > 0" class="languages">
          <span class="section-title-text">{{ t('Language') }}</span>
          <div class="custom-line-left"></div>
          <div v-for="language in cvData.languages" :key="language.id">
            <div class="organization-div-side">
              <div class="skill-name-div">
                <span class="organization-text">{{ language.name }}</span>
              </div>
              <div class="skill-level-div">
                <span v-if="language.level && cvData.showLanguageLevels" class="organization-text">{{ language?.level }}</span>
              </div>

            </div>
          </div>
        </div>
        <div v-if="cvData.certifications?.length" class="main-section">
          <div class="section-title">
            <span class="section-title-text">{{ t('Certification') }}</span>
            <div class="custom-line-left"></div>
          </div>
          <div v-for="certification in cvData.certifications" :key="certification.id" class="section-elements">
            <div class="inline-container">
              <span class="title-text">{{ certification.course }}</span>
              <span v-if="certification.institute" class="title-text">({{ certification.institute }})</span>
              <span v-if="certification.startDate || certification.endDate" class="period-text">, {{ certification?.startDate }} - {{ certification?.endDate }}</span>
            </div>
          </div>
        </div>
        <div v-if="cvData.hobbies && cvData.hobbies.length > 0" class="hobbies">
          <span class="section-title-text">{{ t('Hobby') }}</span>
          <div class="custom-line-left"></div>
          <div v-for="hobby in cvData.hobbies" :key="hobby.id"><div v-html="hobby?.description"></div></div>
        </div>
        <div v-if="cvData.honors && cvData.honors.length > 0" class="honors">
          <span class="section-title-text">{{ t('Honor') }}</span>
          <div class="custom-line-left"></div>
          <ul>
            <li v-for="honor in cvData.honors" :key="honor.id"><span v-html="honor?.description"></span></li>
          </ul>
        </div>
        <div v-if="cvData.publications && cvData.publications.length > 0" class="publications">
          <span class="section-title-text">{{ t('Publication') }}</span>
          <div class="custom-line-left"></div>
          <ul>
            <li v-for="publication in cvData.publications" :key="publication.id"><span v-html="publication?.description"></span></li>
          </ul>
        </div>
        <div v-if="cvData.references && cvData.references.length > 0" class="references">
          <span class="section-title-text">{{ t('Reference') }}</span>
          <ul>
            <li v-for="reference in cvData.references" :key="reference.id">
              <span class="organization-text">{{ reference?.name }}</span>
              <span class="organization-text">{{ reference?.company }}</span>
              <span class="organization-text">{{ reference?.phone }}</span>
              <span class="organization-text">{{ reference?.email }}</span>
            </li>
          </ul>
        </div>
        <div v-if="cvData.exhibitions && cvData.exhibitions.length > 0" class="publications">
          <span class="section-title-text">{{ t('Exhibition') }}</span>
          <div class="custom-line-left"></div>
          <ul>
            <li v-for="exhibition in cvData.exhibitions" :key="exhibition.id"><span v-html="exhibition?.description"></span></li>
          </ul>
        </div>
        <div v-if="cvData.voluntaries && cvData.voluntaries.length > 0" class="publications">
          <span class="section-title-text">{{ t('Voluntary') }}</span>
          <div class="custom-line-left"></div>
          <ul>
            <li v-for="voluntary in cvData.voluntaries" :key="voluntary.id"><span v-html="voluntary?.description"></span></li>
          </ul>
        </div>
      </div>
      <div class="right-section">
        <div class="main-section">
          <div v-if="cvData.contact?.description" class="introduction-div">
            <div class="section-title">
              <span class="section-title-text">{{ t('Summary') }}</span>
            </div>
            <div class="custom-line"> </div>
            <span class="introduction-text" v-html="cvData.contact?.description"></span>
          </div>
        </div>
        <div v-if="cvData.educations && cvData.educations.length > 0" class="main-section">
          <div class="section-title">
            <span class="section-title-text">{{ t('Education') }}</span>
            <div class="custom-line"></div>
          </div>
          <div v-for="education in cvData.educations" :key="education.id" class="section-elements">
            <div class="inline-container">
              <span class="title-text">{{ education.school }}{{ education.location ? ', ' + education.location : '' }}</span>
            </div>
            <div class="organization-div-side">
              <span class="organization-text">
                {{ education.degree }}
                {{ education?.startDate ? ', ' + education?.startDate : '' }}
                {{ education?.endDate ? ' - ' + education.endDate : '' }}
              </span>
            </div>
            <div v-if="education.description && containsTextOutsideHtml(education.description)" class="description-div">
              <span class="description-text" v-html="education.description"></span>
            </div>
          </div>
        </div>
        <div v-if="cvData.experiences && cvData.experiences.length > 0" class="main-section">
          <div class="section-title">
            <span class="section-title-text">{{ t('Experience') }}</span>
            <div class="custom-line"> </div>
          </div>
          <div v-for="experience in cvData.experiences" :key="experience.id" class="section-elements">
            <div class="organization-div-side">
              <span class="title-text">
                {{ experience.title }}{{ experience?.startDate ? ', ' + experience?.startDate : '' }}
                {{ experience?.endDate ? ' - ' + experience.endDate : '' }}
              </span>
            </div>
            <div class="organization-div-side">
              <span class="organization-text">{{ experience.company }}{{ experience.location ? ', ' + experience.location : '' }}</span>
            </div>
            <div v-if="experience.description && containsTextOutsideHtml(experience.description)" class="description-div">
              <span class="description-text" v-html="experience.description"></span>
            </div>
          </div>
        </div>
        <div v-if="cvData.projects && cvData.projects.length > 0" class="main-section">
          <div class="section-title">
            <span class="section-title-text">{{ t('Project') }}</span>
            <div class="custom-line"></div>
          </div>
          <div v-for="project in cvData.projects" :key="project.id" class="section-elements">
            <div class="organization-div-side">
              <span class="title-text">
                {{ project.title }}{{ project?.startDate ? ', ' + project?.startDate : '' }}
                {{ project?.endDate ? ' - ' + project.endDate : '' }}
              </span>
            </div>
            <div v-if="project.description && containsTextOutsideHtml(project.description)" class="description-div">
              <span class="description-text" v-html="project.description"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>

:root {
  --global-font-size: 10px;
}

.skill-name-div{
width: 50%;
margin-right: 10px;
}

.skill-level-div{
  width: 40%;
  padding-right: 5%;
}

.resume {
  width: 595px;
  max-width: 100%;
  max-height: 2524px;
  background: var(--white);
  display: flex;
  text-align: start !important;
}

.languages, .hobbies, .honors, .publications, .references, .skills {
  margin-top: 10px;
  font-size: var(--global-font-size);
  font-weight: 800;
  padding: 5px
}

.organization-div-side {
  display: flex;
  align-items: flex-start;
  padding-bottom: 10px;
}
.organization-text {
  margin-right: 5px;
}
.left-section {
  width: 35%;
  background-color: #759BAF;
  box-sizing: border-box;
  padding-left: 15px;
  text-align: left;
  min-height: 196vh;
  word-wrap: break-word;
}
.contact-basics-div {
  padding-top: 30px;
  text-align: center;
}
.name-text-contact, .fullname-text-contact {
  font-weight: 700;
  font-size: var(--global-font-size);
  line-height: 27.24px;
  color: #333333;
  text-align: left;
  padding-left: 5px;
}
.fullname-text-contact {
  font-size: calc(var(--global-font-size) + 8px);
  text-align: center;
}

.right-section {
  width: 65%;
  padding: 30px;
  box-sizing: border-box;
  padding-left: 15px;
  min-height: 98vh;
}
.h3-sections, .h3-sections-multiple {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.h3-sections-multiple {
  flex-direction: column;
}

ul {
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
}
.introduction-div {
  margin-bottom: 20px;
}

.introduction-text {
  font-size: var(--global-font-size);
  line-height: 1.5;
  color: #333;
}

.custom-line {
  border-bottom: 3px solid #ccc;
  margin: 20px 0;
  width: 350px;
  box-sizing: border-box;
}
.custom-line-left {
  border-bottom: 3px solid #ccc;
  margin: 10px 0;
  width: 170px;
  box-sizing: border-box;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.section-elements {
  margin-bottom: 20px;
}
.section-title-text {
  font-size: calc(var(--global-font-size) + 6px);
  font-weight: 800;
  color: #333333;
}
.title-text {
  font-size: var(--global-font-size);
  font-weight: 800;
  color: #333333;
}
.section-elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.organization-text, .period-text {
  font-size: var(--global-font-size);
  color: #333333;
  font-weight: 800;
}
.description-text {
  font-size: var(--global-font-size);
  color: #333333;
  font-weight: 400;
}
</style>
