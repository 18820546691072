<script setup lang="ts">
import { ref, computed, watch } from "vue";

import DOMPurify from "dompurify";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";
import draggable from "vuedraggable";
import { useStore } from "vuex";

import { useAuth } from "@/auth/useAuth";
import type { Language } from "@/composables/types";
import { modifyLibraryEntry } from "@/services/libraryService";

import { languageLevelMap } from "../../../const";
import { initialStateLanguage } from "../../../utils/initialSectionStates";
const { t } = useI18n();

const props = defineProps<{
  cvId: string,
  evaluated: boolean,
}>();

const { user } = useAuth();

const store = useStore();
const showAdd = ref(true);
const isAdding = ref(false);

watch(
  () => store.state.cvData,
  async (newcvData) => {
    try {
      await modifyLibraryEntry(props.cvId, user.value.details?.uid || "", newcvData);

    } catch (error) {
      console.error("Failed to update library entry:", error);
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

const selectedIndex = ref<number>(-1);
const levels = ref(["Beginner", "Intermediate", "Advanced", "Proficient", "Native"]);

const explanation = ref<string>("");

const levelExplanations: Record<string, string> = {
  Beginner: t("LanguageDetailsSection.Beginner"),
  Intermediate: t("LanguageDetailsSection.Intermediate"),
  Advanced: t("LanguageDetailsSection.Advanced"),
  Proficient: t("LanguageDetailsSection.Proficient"),
  Native: t("LanguageDetailsSection.Native"),
};

const showExplanation = (level: string) => {
  explanation.value = levelExplanations[level];
};

const hideExplanation = () =>  {
  explanation.value = "";
};

const isLevelsToggled = ref(true);

const toggleLevelsSwitch = () => {
  isLevelsToggled.value = !isLevelsToggled.value;
  store.commit("updateShowLanguageLevels", {  data: isLevelsToggled.value });
};

const addLanguage = ref(initialStateLanguage(uuidv4()));

const isFormValid = computed(() => {
  return addLanguage.value.name.trim() !== "" && selectedIndex.value >= 0;
});

const selectLevel = (index: number) =>  {
  selectedIndex.value = index;

  if(selectedIndex.value>-1){addLanguage.value.level = levels.value[selectedIndex.value];}
};

const languages = computed({
  get: () => store.state.cvData.languages,
  set: (value) => store.commit("updateCvSection", { section: "languages", data: value }),
});

const updateField = (fieldName: keyof Language, event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  if (fieldName === "name" || fieldName === "level") {
    addLanguage.value[fieldName] = DOMPurify.sanitize(value);
  }
  if(!isAdding.value){
    store.dispatch("updateCvItem", {
      section: "languages",
      itemId: addLanguage.value.id,
      newData: addLanguage.value,
    });
  }
};

</script>

<template>
  <div class="language-container">
    <div class="score-container">
      <div class="details-container">
        <h2>{{ t('LanguageDetailsSection.Languages') }}</h2>
      </div>
    </div>

    <div class="button-wrapper">
      <div class="toggle-text-container">
        <div class="toggle-switch" :class="{ 'toggled-switch': isLevelsToggled }" @click="toggleLevelsSwitch">
          <div :class="{ toggle: true, toggled: isLevelsToggled }"></div>
        </div>
        <span class="toggle-description">{{ t('SkillDetailsSection.ShowLevelsOnResume') }}</span>
      </div>
      <button
        v-if="showAdd"
        class="add-button"
        @click="
          () => {
            selectedIndex = -1;
            isAdding = true;
            showAdd = false;
          }
        "
      >
        <font-awesome-icon icon="plus" /> {{ t('LanguageDetailsSection.AddALanguage') }}
      </button>
      <div v-if="!showAdd">
        <div class="input-row">
          <div class="input-wrapper">
            <label for="languageName">{{ t('LanguageDetailsSection.Language') }}</label>
            <input
              id="languageName"
              type="text"
              :placeholder="t('LanguageDetailsSection.EnterYourLanguage')"
              :value="addLanguage.name"
              @blur="updateField('name', $event)"
              @mouseleave="updateField('name', $event)"
            />
          </div>

          <div class="input-wrapper">
            <label for="experienceLevel">{{ t('LanguageDetailsSection.ExperienceLevel') }}</label>
            <div class="experience-levels">
              <div
                v-for="(level, index) in levels"
                :key="level"
                :class="{ 'selected-level': index <= selectedIndex }"
                class="level-indicator"
                @click="selectLevel(index)"
                @mouseover="showExplanation(level)"
                @mouseleave="hideExplanation"
              ></div>
            </div>
            <div v-if="explanation" class="level-explanation">{{ explanation }}</div>
          </div>
        </div>
        <button
          type="button"
          class="action-button"
          :disabled="!isFormValid"
          @click="
            () => {
              if (isAdding) {
                store.dispatch('addCvItem', {
                  section: 'languages',
                  item: { ...addLanguage, id: uuidv4() },
                });
                isAdding = false;
              }
              addLanguage = initialStateLanguage(uuidv4());
              showAdd = true;
            }
          "
        >
          {{ isAdding ? t('Add') : t('Save') }}
        </button>
        <button
          v-if="isAdding"
          type="button"
          class="action-button"
          @click="
            () => {
              selectedIndex = -1;
              showAdd = true;
              isAdding = false;
              addLanguage = initialStateLanguage(uuidv4());
            }
          "
        >
          {{ t('Discard') }}
        </button>
      </div>
    </div>
    <div>
      <draggable v-if="showAdd" v-model="languages" itemKey="id" class="draggable-container" tag="div">
        <template #item="{ element, index }">
          <div :key="element.id" class="language-item">
            <svg class="drag-icon" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
              />
            </svg>
            <div class="language-details">
              <label class="language-name">{{ element.name }}</label>
              <label class="language-level">{{ element.level }}</label>
            </div>
            <div class="language-actions">
              <font-awesome-icon icon="edit" class="language-action-icon" @click="
                () => {
                  selectedIndex = languageLevelMap.get(element.level) || 0;
                  showAdd = false;
                  addLanguage = element;
                }
              " />
              <font-awesome-icon
                icon="trash"
                class="language-action-icon"
                @click="
                  store.dispatch('removeCvItem', {
                    section: 'languages',
                    index,
                  })
                "
              />
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<style scoped>
.toggle-text-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 15px;
}
.toggle-switch {
  cursor: pointer;
  display: block;
  position: relative;
  width: 40px;
  height: 20px;
  pointer-events: auto;
  border-radius: 40px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.08);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(84, 56, 218, 1);
  transition: background-color 0.3s ease;
}

.toggled-switch {
  background-color: rgba(84, 56, 218, 0.5);
}

.toggle {
  display: block;
  position: absolute;
  top: 50%;
  left: 3px;
  width: calc(50% - 6px);
  height: calc(100% - 6px);
  transform: translateY(-50%);
  transition: left 0.3s ease;
  border-radius: 40px;
  background-color: rgba(84, 56, 218, 1);
}

.toggled {
  left: calc(50% + 3px);
}

.details-container {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.score-container {
  display: flex;
  flex-direction: row;
}

.drag-icon {
  color: #c2c2c2;
  fill: #c2c2c2;
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.experience-levels {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.level-indicator {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #eee;
  margin: 9px 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.level-indicator.selected-level {
  background-image: radial-gradient(circle at center, #aea8f9 30%, rgba(255, 255, 255, 0.154) 70%);
}

.level-explanation {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  color: #333;
  position: fixed;
}

.language-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.language-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  border-radius: 3%;
  background-color: #e1dfff;
  min-height: 2.6rem;
  padding: 10px;
}

.language-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  gap: 15px;
}

.language-action-icon {
  color: #909090;
}

.language-name {
  margin-left: 20px;
}

.language-level {
  color: #909090;
  margin-right: 20px;
}

.button-wrapper {
  text-align: left;
  margin-top: 15px;
}

.add-button{
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  gap: 10px;
}

.button-wrapper button:hover {
  background-color: rgb(95, 82, 210, 0.7);
}

.button-wrapper button:focus {
  outline: none;
}

.language-container {
  width: 100%;
  margin: auto;
  background: #fff;
  padding: 20px;
  align-items: flex-start;
}

.language-container h2 {
  text-align: start;
}

.language-container p {
  text-align: start;
  color: #7d7d7d;
}

.input-row {
  display: flex;
  gap: 25px;
  margin-bottom: 25px;
  margin-top: 10px;
  align-items: flex-start;
}

.input-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-wrapper label {
  display: block;
  margin-bottom: 5px;
  color: #909090;
  font-weight: bold;
}

.input-wrapper input[type="text"],
.input-wrapper input[type="email"],
.input-wrapper input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #ffffff;
}

.input-wrapper select[id="level"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.action-button {
  cursor: pointer;
  width: 120px;
  height: 36px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: #dedede;
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 24px;
  outline: none;
  margin-right: 10px;
  margin-top: 15px;
}

.draggable-container {
  margin-top: 25px;
}
</style>