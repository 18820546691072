<script setup lang="ts">
import { computed, ref, watch } from "vue";

import DOMPurify from "dompurify";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";
import draggable from "vuedraggable";
import { useStore } from "vuex";

import { useAuth } from "@/auth/useAuth";
import { modifyLibraryEntry } from "@/services/libraryService";
import { getFirstNCharsFromHTML } from "@/utils/string";

import RichTextEditor from "../../../components/RichTextEditor.vue";
import { initialStateExhibition } from "../../../utils/initialSectionStates";

const { t } = useI18n();

const props = defineProps<{
  cvId: string,
  evaluated: boolean,
}>();

const { user } = useAuth();

const store = useStore();
const showAdd = ref(true);
const isAdding = ref(false);

watch(
  () => store.state.cvData,
  async (newcvData) => {
    try {
      await modifyLibraryEntry(props.cvId, user.value.details?.uid || "", newcvData);

    } catch (error) {
      console.error("Failed to update library entry:", error);
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

const addExhibition = ref(initialStateExhibition(uuidv4()));

const exhibitions = computed({
  get: () => store.state.cvData.exhibitions,
  set: (value) => store.commit("updateCvSection", { section: "exhibitions", data: value }),
});

const updateDescription = (newHtmlContent: string) =>  {
  addExhibition.value.description = DOMPurify.sanitize(newHtmlContent);
};

</script>

<template>
  <div class="exhibition-details">
    <div class="score-container">
      <div class="details-container">
        <h2>{{ t('ExhibitionDetailsSection.Exhibition') }}</h2>
      </div>
    </div>

    <div class="button-wrapper">
      <button
        v-if="showAdd"
        class="add-button"
        @click="
          () => {
            isAdding = true;
            showAdd = false;
          }
        "
      >
        {{ t('ExhibitionDetailsSection.AddAExhibition') }} <font-awesome-icon icon="plus" />
      </button>
      <div v-if="!showAdd">
        <div class="input-wrapper">
          <label for="summary">{{ t('ExhibitionDetailsSection.Summary') }}</label>
        </div>
        <RichTextEditor :htmlValue="addExhibition.description" :style="{ height: '150px' }" @updateHtmlValue="updateDescription"/>
        <button
          type="button"
          class="action-button"
          @click="
            () => {
              if (isAdding) {
                store.dispatch('addCvItem', {
                  section: 'exhibitions',
                  item: { ...addExhibition, id: uuidv4() },
                });
                isAdding = false;
              }
              addExhibition = initialStateExhibition(uuidv4());
              showAdd = true;
            }
          "
        >
          {{ isAdding ? t('Add') : t('Save') }}
        </button>
        <button
          v-if="isAdding"
          type="button"
          class="action-button"
          @click="
            () => {
              showAdd = true;
              isAdding = false;
              addExhibition = initialStateExhibition(uuidv4());
            }
          "
        >
          {{ t('Discard') }}
        </button>
      </div>
    </div>
    <div>
      <draggable
        v-if="showAdd"
        v-model="exhibitions"
        itemKey="id"
        class="draggable-container"
        tag="div"
      >
        <template #item="{ element, index }">
          <div :key="element.id" class="exhibition-item">
            <svg class="icon" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0V0z" fill="none"></path>
              <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
            </svg>
            <div class="exhibition-content">
              <label class="exhibition-description">{{  getFirstNCharsFromHTML(element.description, 15) }}</label>
            </div>
            <div class="exhibition-actions">
              <font-awesome-icon
                icon="edit"
                class="exhibition-action-icon"
                @click="
                  () => {
                    showAdd = false;
                    addExhibition = element;
                  }
                "
              />
              <font-awesome-icon
                icon="trash"
                class="exhibition-action-icon"
                @click="
                  store.dispatch('removeCvItem', {
                    section: 'exhibitions',
                    index,
                  })
                "
              />
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<style scoped>

.details-container {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.score-container {
  display: flex;
  flex-direction: row;
}

.icon {
  color: #c2c2c2;
  fill: #c2c2c2;
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.exhibition-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.exhibition-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  border-radius: 3%;
  background-color: #e1dfff;
  min-height: 2.6rem;
  padding: 10px;
}

.exhibition-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  gap: 15px;
}

.exhibition-action-icon {
  color: #909090;
}

.exhibition-description {
  margin-left: 20px;
}

.exhibition-date {
  color: #909090;
  margin-right: 20px;
}

.button-wrapper {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 25px;
}

.add-button{
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  gap: 10px;
}

.button-wrapper button:hover {
  background-color: rgb(95, 82, 210, 0.7);
}

.button-wrapper button:focus {
  outline: none;
}

.exhibition-details {
  width: 100%;
  margin: auto;
  background: #fff;
  padding: 20px;
  align-items: flex-start;
}

.exhibition-details h2 {
  text-align: start;
}

.exhibition-details p {
  text-align: start;
  color: #7d7d7d;
}

.input-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-wrapper label {
  display: block;
  margin-bottom: 5px;
  color: #909090;
  font-weight: bold;
}

.input-wrapper input[type="text"],
.input-wrapper input[type="email"],
.input-wrapper input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.input-wrapper input[type="date"] {
  width: 50%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.action-button {
  cursor: pointer;
  width: 120px;
  height: 36px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: #dedede;
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 24px;
  outline: none;
  margin-right: 10px;
  margin-top: 15px;
}

.draggable-container {
  margin-top: 25px;
}

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
</style>

