<script setup lang="ts">
import { ref } from "vue";

import { GlobalWorkerOptions } from "pdfjs-dist";
import { useI18n } from "vue-i18n";

GlobalWorkerOptions.workerSrc = "pdfjs-dist/build/pdf.worker.js";

const { t } = useI18n();
const fileInput = ref<HTMLElement | null>(null);
const emit = defineEmits(["pdf-loaded"]);
const isDragging = ref(false);

const clickUploadButton = () => {
  fileInput.value?.click();
};
const processFile = (file: File) => {
  if (file.type !== "application/pdf") {
    alert(t("cvEvaluation.InvalidFileType"));
    return;
  }

  const maxSize = 5 * 1024 * 1024;
  if (file.size > maxSize) {
    alert(t("cvEvaluation.FileTooLarge"));
    return;
  }

  emit("pdf-loaded", file);
};

const handlePdfUpload = async (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  if (files && files[0]) {
    processFile(files[0]);
  }
};

const handleDragOver = (event: DragEvent) => {
  event.preventDefault();
  isDragging.value = true;
};

const handleDragLeave = () => {
  isDragging.value = false;
};

const handleDrop = (event: DragEvent) => {
  event.preventDefault();
  isDragging.value = false;
  const files = event.dataTransfer?.files;
  if (files && files[0]) {
    processFile(files[0]);
  }
};
</script>

<template>
  <div
    class="file-upload-container"
    @dragover="handleDragOver"
    @dragleave="handleDragLeave"
    @drop="handleDrop"
  >
    <div class="card" :class="{ 'dragging': isDragging }">
      <div class="icon-container">
        <svg class="icon" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none"/>
          <path d="M19.35 10.04A7.49 7.49 0 0 0 12 4C9.11 4 6.6 5.64 5.35 8.04A5.994 5.994 0 0 0 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95A5.469 5.469 0 0 1 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11A2.98 2.98 0 0 1 22 15c0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"/>
        </svg>
      </div>
      <div class="text large">{{ t('cvEvaluation.DragAndDrop') }}</div>
      <div class="text small">{{ t('cvEvaluation.Or') }}</div>
      <button class="button-row" @click="clickUploadButton">{{ t('cvEvaluation.ImportCV') }}</button>
      <input ref="fileInput" type="file" style="display: none" accept=".pdf" @change="handlePdfUpload"/>
    </div>
  </div>
</template>

<style scoped>
.file-upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    margin-bottom: 100px;
    margin-top: 100px;
}
.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon {
    fill: #909090;
    width: 57px;
    height: 58px;
}
.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    width: 646px;
    height: 360px;
    background-color: #ffffff;
    border-radius: 18px;
    border: 2px dashed #505050;
    box-sizing: border-box;
}

.card.dragging {
    background-color: #f0f0f0;
    border-color: #5438da;
}

.text {
    color: #909090;
    text-align: center;
}
.large {
    font-size: 24px;
    font-family: 'Montserrat';
    font-weight: 400;
    line-height: 31px;
}
.small {
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 400;
    line-height: 23px;
}
.button-row {
    cursor: pointer;
    width: 154px;
    height: 56px;
    padding: 0px 8px;
    border: 2px solid #5438da;
    background-color: transparent;
    color: #5438da;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 400;
    line-height: 19px;
    border-radius: 8px;
    outline: none;
}

</style>
