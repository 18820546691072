<script setup lang="ts">
import { onMounted, ref, watch, onUnmounted, nextTick } from "vue";

import { useI18n } from "vue-i18n";

import type { CvData } from "@/composables/types";
import type { HTMLElementWithVNode, ForbiddenZone } from "@/views/cv-creation-view/const";
import { adjustLayout } from "@/views/cv-creation-view/utils/adjustPreview";
import { containsTextOutsideHtml } from "@/views/cv-creation-view/utils/text";

const { cvData, adjust = false } = defineProps<{
  cvData: CvData,
  adjust?: boolean
}>();

const { t } = useI18n();

const divManipulatorRef = ref<HTMLElementWithVNode | null>(null);
const forbiddenZones = ref<ForbiddenZone[]>([]);

const updateForbiddenZones = () => {
  const children = divManipulatorRef.value ? Array.from(divManipulatorRef.value.children) as HTMLElement[] : [];
  if (children.length > 0) {

    const rectForFirst = children[0].getBoundingClientRect();
    forbiddenZones.value = [
      { start: rectForFirst.y + 700, end: rectForFirst.y + 880 },
      { start: rectForFirst.y + 1550, end: rectForFirst.y + 1700 },
      { start: rectForFirst.y + 2350, end: rectForFirst.y + 2490 },
      { start: rectForFirst.y + 3150, end: rectForFirst.y + 3290 },
    ];
  }
};

watch(() => cvData, () => {
  updateForbiddenZones();
  if(!adjust)
  {adjustLayout(forbiddenZones.value, divManipulatorRef);}
}, { deep: true, immediate: true });

watch(cvData, (newValue) => {
  const fontSize = `${newValue.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
});

onMounted(async () => {
  await nextTick();
  updateForbiddenZones();
  if(!adjust)
  {adjustLayout(forbiddenZones.value, divManipulatorRef);}

  const fontSize = `${cvData.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
  window.addEventListener("resize", () => {
    updateForbiddenZones();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  });
});

onUnmounted(() => {
  window.removeEventListener("resize", () => {
    updateForbiddenZones();
    if(!adjust)
    {adjustLayout(forbiddenZones.value, divManipulatorRef);}
  });
});
</script>

<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
  <div ref="divManipulatorRef" class="div-manipulator" :style="{ fontFamily: cvData.resumeFontFamily }">
    <section class="resume">
      <div class="top-section">
        <span class="fullname-text-contact">{{ cvData.contact?.firstName }} {{ cvData.contact?.lastName }}</span>
        <span class="organization-text">{{ cvData.contact?.jobTitle }}</span>
        <div class="contacts-row">
          <p v-if="cvData.contact?.birthDate" class="organization-text"><i class="fa fa-birthday-cake"></i> {{ cvData.contact?.birthDate }}</p>
          <p v-if="cvData.contact?.phone" class="organization-text"><i class="fas fa-phone"></i> {{ cvData.contact?.phone }}</p>
        </div>
        <div class="contacts-row">
          <p v-if="cvData.contact?.email" class="organization-text"><i class="fas fa-envelope"></i> {{ cvData.contact?.email }}</p>
          <p v-if="cvData.contact?.address || cvData.contact?.country || cvData.contact?.city" class="organization-text"><i class="fas fa-map-marker-alt"></i>
            {{ cvData.contact.country }}{{ cvData.contact.country && cvData.contact?.city ? ',' : '' }}{{ cvData.contact.city }}{{ cvData.contact.city && cvData.contact?.address ? ',' : '' }}{{ cvData.contact?.address }}
          </p>
        </div>
        <span class="introduction-text" v-html="cvData.contact?.description"></span>
      </div>
      <div class="custom-line-long"></div>
      <div class="bottom-section">
        <div class="left-section">
          <div v-if="cvData.educations && cvData.educations.length > 0" class="main-section-centered">
            <div class="section-title">
              <span class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Education') }}</span>
            </div>
            <div class="main-section-left">
              <div v-for="education in cvData.educations" :key="education.id" class="section-elements">
                <div class="inline-container">
                  <span style="font-weight: 600;" class="title-text">{{ education.school }}</span>
                  <span v-if="education.location" class="title-text">{{ education.location }}</span>
                  <span v-if="education.degree" class="title-text">{{ education.degree }}</span>
                  <span class="title-text">{{ education?.startDate ? education?.startDate : '' }}{{ education?.endDate ? ' - ' + education.endDate : '' }}</span>
                  <div v-if="education.description && containsTextOutsideHtml(education.description)" class="description-div">
                    <span class="description-text" v-html="education.description"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="main-section-combined">
            <div class="main-section">
              <div v-if="cvData.skills && cvData.skills.length > 0" class="main-section-left">
                <div class="section-title">
                  <span class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Skill') }}</span>
                </div>
                <div v-for="skill in cvData.skills" :key="skill.id" class="skill-row">
                  <div class="organization-div-side">
                    <span style="font-weight: 600;" class="organization-text">{{ skill?.name }}</span>
                    <span v-if="skill.level && cvData.showSkillLevels" class="organization-text">{{ skill?.level }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="main-section">
              <div v-if="cvData.languages && cvData.languages.length > 0" class="main-section-left">
                <div class="section-title">
                  <span class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Language') }}</span>
                </div>
                <div v-for="languages in cvData.languages" :key="languages.id" class="skill-row">
                  <div class="organization-div-side">
                    <span style="font-weight: 600;" class="organization-text">{{ languages?.name }}</span>
                    <span v-if="languages.level && cvData.showLanguageLevels" class="organization-text">{{ languages?.level }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="cvData.hobbies && cvData.hobbies.length > 0" class="main-section-centered">
            <div class="section-title">
              <span class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Hobby') }}</span>
            </div>
            <div class="main-section-left">
              <div v-if="cvData.hobbies && cvData.hobbies.length > 0" class="section-elements">
                <div v-for="hobby in cvData.hobbies" :key="hobby.id" class="inline-container">
                  <div v-html="hobby.description"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-section">
          <div v-if="cvData.experiences && cvData.experiences.length > 0" class="main-section-centered">
            <div class="section-title">
              <span class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Experience') }}</span>
            </div>
            <div v-for="experience in cvData.experiences" :key="experience.id" class="section-elements">
              <div class="organization-div-side">
                <span v-if="experience.title" class="title-text-experience">{{ experience.title }}</span>
                <span v-if="experience.startDate || experience.endDate" class="title-text">{{ experience?.startDate ? experience?.startDate : '' }}{{ experience?.endDate ? ' - ' + experience.endDate : '' }}</span>
                <span v-if="experience.company" class="title-text">{{ experience.company }}</span>
                <span v-if="experience.location" class="title-text">{{ experience.location }}</span>
              </div>
              <div v-if="experience.description && containsTextOutsideHtml(experience.description)" class="description-div">
                <span class="description-text" v-html="experience.description"></span>
              </div>
            </div>
          </div>
          <div v-if="cvData.projects && cvData.projects.length > 0" class="main-section-centered">
            <div class="section-title">
              <span class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Project') }}</span>
            </div>
            <div v-for="project in cvData.projects" :key="project.id" class="section-elements">
              <div class="organization-div-side">
                <span v-if="project.title" class="title-text-experience">{{ project.title }}</span>
                <span v-if="project.startDate || project.endDate" class="title-text">{{ project?.startDate ? project?.startDate : '' }}{{ project?.endDate ? ' - ' + project.endDate : '' }}</span>
              </div>
              <div v-if="project.description && containsTextOutsideHtml(project.description)" class="description-div">
                <span class="description-text" v-html="project.description"></span>
              </div>
            </div>
          </div>
          <div class="main-section-centered">
            <div v-if="cvData.references && cvData.references.length > 0" class="references">
              <span class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Reference') }}</span>
              <div v-for="reference in cvData.references" :key="reference.id" class="section-elements">
                <span v-if="reference?.name?.length" class="organization-text">{{ reference?.name }}</span>
                <span v-if="reference?.company?.length" class="organization-text">{{ reference?.company }}</span>
                <span v-if="reference?.phone?.length" class="organization-text">{{ reference?.phone }}</span>
                <span v-if="reference?.email?.length" class="organization-text">{{ reference?.email }}</span>
              </div>
            </div>
          </div>
          <div v-if="cvData.publications && cvData.publications.length > 0" class="main-section-centered">
            <div class="publications">
              <span class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Publication') }}</span>
              <div v-for="publication in cvData.publications" :key="publication.id">
                <div v-html="publication?.description"/>
              </div>
            </div>
          </div>
          <div v-if="cvData.honors && cvData.honors.length > 0" class="main-section-centered">
            <div class="publications">
              <span class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Honor') }}</span>
              <div v-for="honor in cvData.honors" :key="honor.id">
                <div v-html="honor?.description"/>
              </div>
            </div>
          </div>
          <div v-if="cvData.voluntaries && cvData.voluntaries.length > 0" class="main-section-centered">
            <div class="publications">
              <span class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Voluntary') }}</span>
              <div v-for="voluntary in cvData.voluntaries" :key="voluntary.id">
                <div v-html="voluntary?.description"/>
              </div>
            </div>
          </div>
          <div v-if="cvData.exhibitions" class="main-section-centered">
            <div class="publications">
              <span class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Exhibition') }}</span>
              <div v-for="exhibition in cvData.exhibitions" :key="exhibition.id">
                <div v-html="exhibition?.description"/>
              </div>
            </div>
          </div>
          <div v-if="cvData.certifications && cvData.certifications.length > 0" class="references">
            <span class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Certification') }}</span>
            <ul>
              <li v-for="certification in cvData.certifications" :key="certification.id" class="section-elements">
                <span class="organization-text">{{ certification?.course }}</span>
                <span class="organization-text">{{ certification?.institute }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>

:root {
  --global-font-size: 10px;
}

.resume {
  width: 595px;
  max-height: 2524px;
  max-width: 100%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.top-section {
  background-color: white;
  box-sizing: border-box;
  padding-top: 20px;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bottom-section {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.contacts-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.languages, .hobbies, .honors, .publications, .references, .skills, .certifications {
  margin-top: 10px;
  text-align: flex-start;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  font-size: var(--global-font-size);
}

.organization-div-side {
  display: flex;
  align-items: start;
  width: 100%;
  flex-direction: column;
}
.organization-text {
  margin: 5px;
  text-align: start !important;
  font-size: var(--global-font-size);
}
.left-section {
  width: 50%;
  background-color: white;
  box-sizing: border-box;
  padding: 10px;
  text-align: start !important;
  align-items: center;
  min-height: 196vh;
  border-right: 2px solid rgb(213, 120, 21);

}
.contact-basics-div {
  padding-top: 50px;
  text-align: center;
}
.name-text-contact, .fullname-text-contact {
  font-weight: 700;
  font-size: var(--global-font-size);
  line-height: 27.24px;
  color: #333333;
  text-align: left;
  padding-left: 5px;
}
.fullname-text-contact {
  font-size: calc(var(--global-font-size) + 8px);
  text-align: center;
}

.right-section {
  width: 50%;
  box-sizing: border-box;
  padding-left: 15px;
  min-height: 98vh;
  text-align: start !important;
}
.h3-sections, .h3-sections-multiple {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.h3-sections-multiple {
  flex-direction: column;
}

ul {
  padding-left: 10px;
}

li {
  margin-bottom: 5px;
}
.introduction-div {
  margin-bottom: 20px;
}

.introduction-text {
  padding-left: 10px;
  padding-right: 10px;
  font-size: var(--global-font-size);
  line-height: 1.5;
  color: #333;
}
.custom-line-long {
  border-bottom: 3px solid rgb(213, 120, 21);
  margin: 20px 0;
  width: 500pxpx;
  box-sizing: border-box;
}
.custom-line {
  border-bottom: 3px solid #ccc;
  margin: 20px 0;
  width: 350px;
  box-sizing: border-box;
}
.custom-line-left {
  border-bottom: 3px solid #ccc;
  margin: 10px 0;
  width: 170px;
  box-sizing: border-box;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.main-section-centered {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
  margin-top: 10px;
}
.main-section-combined {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 10px;

}

.main-section-left {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.section-title-text {
  font-size: calc(var(--global-font-size) + 6px);
  font-weight: 800;
  color: #333333;
}
.section-title-text-left {
  font-size: calc(var(--global-font-size) + 6px);
  font-weight: 800;
  color: #333333;
  padding-bottom: 5px;
}
.title-text {
  font-size: var(--global-font-size);
  color: #333333;
}
.title-text-experience {
  font-size: var(--global-font-size);
  color: #333333;
  font-weight: 600;
  padding-bottom: 10px;
}
.section-elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 10px;
}
.section-elements-side {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px;
}
.organization-text, .period-text {
  font-size: var(--global-font-size);
  color: #333333;
  text-align: left;
}
.description-text {
  font-size: var(--global-font-size);
  color: #333333;
  font-weight: 400;
}
.inline-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #333333;
  font-size: var(--global-font-size);
  text-align: flex-start;
}
.skill-row {
  display: flex;
  align-items: center;
  margin: 5px 0;
  width: 100%;
}
.section-title {
  padding-bottom: 5px;
  text-align: center;
}

</style>
