<script setup lang="ts">

import { useI18n } from "vue-i18n";

const { t } = useI18n();

const facts = [
  { textKey: "facts.usersWorldwide", subtextKey: "facts.usersWorldwideSubtext" },
  { textKey: "facts.cvsCreated", subtextKey: "facts.cvsCreatedSubtext" },
  { textKey: "facts.cvsEvaluated", subtextKey: "facts.cvsEvaluatedSubtext" },
];
</script>

<template>
  <div class="facts-container">
    <div v-for="(fact, index) in facts" :key="index" class="fact-card">
      <p class="fact-text">{{ t(fact.textKey) }}</p>
      <p class="fact-subtext">{{ t(fact.subtextKey) }}</p>
    </div>
  </div>
</template>

<style scoped>
.facts-container {
    top: 1582px;
    left: 973px;
    width: 327px;
    height: 250px;
    background-color: #f2f2f2;
    justify-content: space-between;
    display: flex;
    width: 100%;
    background-color: #ebe8fc;
    padding-bottom: 100px;
    gap: 15px;

}

.fact-card {
  width: 0%;
  width: 327px;
  height: 232px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ebe8fc;
}

.fact-text {
    color: #5438da;
    font-size: 80px;
    font-weight: 500;
    line-height: 104px;
    margin-bottom: 5px;
}

.fact-subtext {
    color: #030303;
    font-size: 24px;
    line-height: 31px;
}

</style>