<script setup lang="ts">
import { computed, ref, watch } from "vue";

import DOMPurify from "dompurify";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";
import draggable from "vuedraggable";
import { useStore } from "vuex";

import { useAuth } from "@/auth/useAuth";
import AuthModal from "@/components/AuthModal.vue";
import UpgradePremium from "@/components/UpgradePremium.vue";
import type { Project } from "@/composables/types";
import { enhanceProject } from "@/services/enhanceService";
import { modifyLibraryEntry } from "@/services/libraryService";

import DescriptionEnhanceModal from "../../../components/DescriptionEnhanceModal.vue";
import EnhanceButton from "../../../components/EnhanceButton.vue";
import RichTextEditor from "../../../components/RichTextEditor.vue";
import { formatDate } from "../../../utils/formatDate";
import { initialStateProject } from "../../../utils/initialSectionStates";
import { removeBackgroundColorsFromSection } from "../../../utils/removeDescriptionBackgroundColors";

const { t } = useI18n();

const props = defineProps<{
  cvId: string,
  evaluated: boolean,
}>();

const { user } = useAuth();

const store = useStore();
const showAdd = ref(true);
const isAdding = ref(false);
const showDescriptionModal = ref(false);
const enhanced = ref(false);
const isLoading = ref(false);
const richTextEditorRef = ref<any>();
const upgradePremiumVisible = ref(false);
const authModalVisible = ref(false);
const creditNotEnough = ref(false);

const closeModal = () => {
  showDescriptionModal.value = false;
};
const openModal = () => {
  showDescriptionModal.value = true;

};

const summaryEnhancements = ref([]);

watch(
  () => store.state.cvData,
  async (newcvData) => {
    try {

      await modifyLibraryEntry(props.cvId, user.value.details?.uid || "", removeBackgroundColorsFromSection(newcvData, "projects"));

    } catch (error) {
      console.error("Failed to update library entry:", error);
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

const addProject = ref<Project>(initialStateProject(uuidv4()));

const projects = computed({
  get: () => store.state.cvData.projects,
  set: (value) => store.commit("updateCvSection", { section: "projects", data: value }),
});

const handleCurrentProject = () => {
  if (addProject.value.isCurrentProject) {
    addProject.value.endDate = t("cvCreationView.Present");
  } else {
    addProject.value.endDate = "";
  }
  store.dispatch("updateCvItem", {
    section: "projects",
    itemId: addProject.value.id,
    newData: addProject.value,
  });

};

const updateField = (fieldName: keyof Project, event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  if (fieldName === "title" ||  fieldName === "description" || fieldName === "startDate" || fieldName === "endDate") {
    addProject.value[fieldName] = DOMPurify.sanitize(value);
  } if (fieldName === "isCurrentProject") {
    addProject.value[fieldName] = value === "true";
  }
  if(!isAdding.value){
    store.dispatch("updateCvItem", {
      section: "projects",
      itemId: addProject.value.id,
      newData: addProject.value,
    });
  }
};

const updateDescription = (newHtmlContent: string) =>  {
  addProject.value.description = DOMPurify.sanitize(newHtmlContent);
  store.dispatch("updateCvItem", {
    section: "projects",
    itemId: addProject.value.id,
    newData: addProject.value,
  });
};

const updateDescriptionEnhance = (newHtmlContent: string) =>  {
  addProject.value.description = DOMPurify.sanitize(newHtmlContent);
  store.dispatch("updateCvItem", {
    section: "projects",
    itemId: addProject.value.id,
    newData: addProject.value,
  });
  setTimeout(() => {
    richTextEditorRef.value?.updateInnerHtml();
  }, 500);
};

const discardEnhanced = () => {
  enhanced.value = false;
};

const getSummaryEnhancement = async() => {
  if (!user.value.authUser) {
    authModalVisible.value = true;
    return;
  }
  if (user.value.details?.accountStatus !== "Premium") {
    upgradePremiumVisible.value = true;
    return;
  }
  isLoading.value = true;
  discardEnhanced();
  try {
    const { result: response, error: enhanceError } = await enhanceProject(addProject.value.description || "", t("Locale"), t("SectionStringProjects"), user.value.details?.id || "");
    if (enhanceError?.value && enhanceError.value.indexOf("402") !== -1) {
      creditNotEnough.value = true;
      return;
    }

    const jsonString2 = response?.enhancedSummaries;
    const jsonObj = JSON.parse(jsonString2 || "");
    summaryEnhancements.value = jsonObj.enhanced_summaries;
    enhanced.value = true;

    openModal();

  } catch (e) {
    console.error("Error during enhancement or parsing:", e);
  } finally {
    isLoading.value = false;
  }

};

</script>

<template>
  <AuthModal v-if="authModalVisible" @update:visible="authModalVisible = $event"/>
  <UpgradePremium v-if="creditNotEnough" :premiumReasonText="t('NotEnoughCreditsTextPremium')" :premiumHeader="t('NotEnoughCreditsHeaderPremium')" @close="creditNotEnough = false"/>
  <UpgradePremium v-if="upgradePremiumVisible" showFeatures @close="upgradePremiumVisible = false"/>
  <div class="projects-container">
    <div class="score-section">
      <div class="header-section">
        <h2>{{ t('ProjectDetailsSection.Projects') }}</h2>
      </div>
    </div>

    <div class="project-container-div">
      <button
        v-if="showAdd"
        class="project-container-div-button"
        @click="
          () => {
            isAdding = true;
            showAdd = false;
          }
        "
      >
        {{ t('ProjectDetailsSection.AddAWorkProject') }} <font-awesome-icon icon="plus" />
      </button>
      <div v-if="!showAdd">

        <div class="input-row">
          <div class="input-field">
            <label for="projectTitle">{{ t('ProjectDetailsSection.Title') }}</label>
            <input
              id="projectTitle"
              type="text"
              :placeholder="t('ProjectDetailsSection.EnterProjectTitle')"
              :value="addProject.title"
              @blur="updateField('title', $event)"
            />
          </div>

          <div class="input-field">
            <label for="startDate">{{ t('StartAndEndDate') }}</label>
            <div class="date-container">
              <input
                :id="'startDate'"
                class="date-input"
                placeholder="MM/YYYY"
                :value="addProject.startDate"
                @blur="updateField('startDate', $event)"
                @input="formatDate($event, addProject, 'startDate')"
              />
              <input
                id="endDate"
                class="date-input"
                placeholder="MM/YYYY"
                :value="addProject.endDate"
                :disabled="addProject.isCurrentProject"
                @blur="updateField('endDate', $event)"
                @input="formatDate($event, addProject, 'endDate')"
              />
            </div>
            <div class="current-project-container">
              <input
                id="currentProject"
                v-model="addProject.isCurrentProject"
                type="checkbox"
                class="current-project-checkbox"
                @change="handleCurrentProject"
              />
              <label for="currentProject">{{ t('ProjectDetailsSection.CurrentProject') }}</label>
            </div>
          </div>
        </div>
        <div class="input-field">
          <DescriptionEnhanceModal v-if="showDescriptionModal" :summaryEnhancements="summaryEnhancements" :currentDescription="addProject.description || ''" @updateDescription="updateDescriptionEnhance" @closeModal="closeModal" @discardEnhanced="discardEnhanced"/>
          <label for="projectSummary">{{ t('ProjectDetailsSection.Summary') }}</label>
        </div>
        <RichTextEditor ref="richTextEditorRef" :htmlValue="addProject.description" :enhanced="enhanced" :style="{ height: '150px' }" @updateHtmlValue="updateDescription" @openModal="openModal"/>

        <div class="buttons-div">
          <button
            type="button"
            class="submit-button"
            @click="
              () => {
                if (isAdding) {
                  store.dispatch('addCvItem', {
                    section: 'projects',
                    item: { ...addProject, id: uuidv4() },
                  });
                  isAdding = false;
                }
                addProject = initialStateProject(uuidv4());
                showAdd = true;
                enhanced = false;
              }
            "
          >
            {{ isAdding ? t('Add') : t('Save') }}
          </button>
          <button
            v-if="isAdding"
            type="button"
            class="submit-button"
            @click="
              () => {
                showAdd = true;
                isAdding = false;
                enhanced = false;
                addProject = initialStateProject(uuidv4());
              }
            "
          >
            {{ t('Discard') }}
          </button>
          <EnhanceButton :isLoading="isLoading" :width="'198px'" :height="'36px'" @enhance="getSummaryEnhancement" />
        </div>
      </div>
    </div>
    <div>
      <draggable
        v-if="showAdd"
        v-model="projects"
        itemKey="id"
        class="draggable-container"
        tag="div"
      >
        <template #item="{ element, index }">
          <div :key="element.id" class="project-item">
            <svg class="drag-icon" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0V0z" fill="none"/>
              <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
            </svg>
            <div class="project-details">
              <label class="project-title">{{ element.title }}</label>
              <label class="project-date">{{ element.startDate }}</label>
            </div>
            <div class="project-actions">
              <font-awesome-icon
                icon="edit"
                class="project-action-icon"
                @click="
                  () => {
                    showAdd = false;
                    addProject = element;
                  }
                "
              />
              <font-awesome-icon
                icon="trash"
                class="project-action-icon"
                @click="
                  store.dispatch('removeCvItem', {
                    section: 'projects',
                    index,
                  })
                "
              />
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<style scoped>

.buttons-div{
  display: flex;
  margin-top: 18px;
}

.header-section{
  display: flex;
  flex-direction: column;
  width: 70%;
}

.score-section{
  display: flex;
  flex-direction: row
}

.drag-icon{
  color: #c2c2c2;
  fill: #c2c2c2;
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.project-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
.project-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  border-radius: 3%;
  background-color: #e1dfff;
  min-height: 2.6rem;
  padding: 10px;
}
.project-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  gap: 15px;
}

.project-action-icon {
  color: #909090;
}

.project-title {
  margin-left: 20px;
}
.project-date {
  color: #909090;
  margin-right: 20px;
}

.current-project-container {
  display: flex;
  font-size: small;
  gap: 8px;
  align-self: flex-end;
  margin-right: 25%;
}

.current-project-label {
  margin: 0;
  font-size: 0.78rem;
  color: #848484 !important;
}

.current-project-checkbox ~ label::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.current-project-checkbox:checked ~ label::before {
  background-color: rgb(95, 82, 210, 0.7);
}

.current-project-checkbox {
  opacity: 0;
}

.current-project-checkbox ~ label {
  position: relative;
  cursor: pointer;
}

.project-container-div {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 25px;
}

.project-container-div-button {
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  gap: 10px;
}

.project-container-div-button:hover {
  background-color: rgb(95, 82, 210, 0.7);
}

.project-container-div-button:focus {
  outline: none;
}

.projects-container {
  width: 100%;
  margin: auto;
  background: #fff;

  align-items: flex-start;
}

.projects-container h2 {
  text-align: start;
}

.projects-container p {
  text-align: start;
  color: #7d7d7d;
}

.input-row {
  display: flex;
  gap: 20px;
  margin-bottom: 25px;
  margin-top: 45px;
  align-items: flex-start;
}

.input-field {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.date-container {
  gap: 30px;
  display: flex;
}

.input-field label {
  display: block;
  margin-bottom: 5px;
  color: #909090;
  font-weight: bold;
}

.input-field input[type="text"],
.input-field input[type="email"],
.input-field input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.input-field input[type="date"] {
  width: 50%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.date-input {
  width: 50%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.action-button {
  background-color: #aa93f3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
}

.draggable-container {
  margin-top: 25px;
}

.submit-button {
  cursor: pointer;
  width: 120px;
  height: 36px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: #dedede;
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 24px;
  outline: none;
  margin-right: 10px
}

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
</style>

