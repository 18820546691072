import type { CvData, Resume } from "@/composables/types";
import useHttp from "@/composables/useHttp";
import { API_URL } from "@/config";

export const createLibraryEntry = async (cvData: CvData, uid: string) => {
  const { data, error, loading, execute } = useHttp<CvData>(API_URL + "/api/libraryEntry", {
    method: "POST",
  });

  const result = await execute({ cvData, uid });
  return { data, error, loading, result };
};

export const modifyLibraryEntry = async (id: string, uid: string, cvData: CvData) => {
  const { data, error, loading, execute } = useHttp<CvData>(API_URL + "/api/libraryEntry", {
    method: "PUT",
  });

  const result = await execute({ id, cvData, uid });
  return { data, error, loading, result };
};

export const deleteLibraryEntry = async (id: string) => {
  const { data, error, loading, execute } = useHttp<string>(API_URL + "/api/libraryEntry", {
    method: "DELETE",
  });

  const result = await execute({ id });
  return { data, error, loading, result };
};

export const getLibraryEntry = async (id: string) => {
  const { data, error, loading, execute } = useHttp<Resume>(API_URL + `/api/libraryEntry/${id}`, {
    method: "GET",
  });

  const result = await execute();
  return { data, error, loading, result };
};

export const getLibraryEntries = async (uid: string) => {
  const { data, error, loading, execute } = useHttp<Resume[]>(API_URL + `/api/libraryEntries/${uid}`, {
    method: "GET",
  });

  const result = await execute();
  return { data, error, loading, result };
};

export const getLibraryEntriesCount = async (uid: string) => {
  const { data, error, loading, execute } = useHttp<{count: number}>(API_URL + `/api/libraryEntriesCount/${uid}`, {
    method: "GET",
  });

  const result = await execute();
  return { data, error, loading, result };
};

export const libraryEntryExist = async (uid: string) => {
  const { data, error, loading, execute } = useHttp<{ exists: boolean }>(API_URL + `/api/libraryEntries/${uid}/exists`, {
    method: "GET",
  });

  const result = await execute();
  return { data, error, loading, result };
};
