import type { Component } from "vue";
import { createApp } from "vue";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import type { Store } from "vuex";

import type { CvData, CvState } from "@/composables/types";
import i18n from "@/languages/i18n";

type  CurrentTemplate = {
  component: Component;
}

export const previewPdf = async (currentTemplate: CurrentTemplate, myStore: Store<CvState>, resumeData: CvData, download: boolean, singlePage: boolean) => {
  const scaleFactor = download ? 4 : 2;
  if(!singlePage){

    const tempDiv = document.createElement("div");
    tempDiv.style.position = "absolute";
    tempDiv.style.left = "-9999px";
    tempDiv.style.height = "auto";
    document.body.appendChild(tempDiv);

    const app = createApp(currentTemplate.component, { cvData: resumeData });
    app.use(myStore);
    app.use(i18n);
    app.mount(tempDiv);

    await new Promise(resolve => requestAnimationFrame(resolve));

    const canvas = await html2canvas(tempDiv, {
      scale: scaleFactor,
      useCORS: true,
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.98);

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });
    const imgProps = pdf.getImageProperties(imgData);

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = pdfWidth;
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 0;

    if (imgHeight <= pdfHeight) {
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
    } else {
      while (heightLeft >= 0) {
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pdfHeight;

        if (heightLeft > 0) {
          pdf.addPage();
          position = -imgHeight + heightLeft;
        }
      }
    }

    const textContent = tempDiv.innerText || tempDiv.textContent || "";
    const lines = textContent.split("\n");

    pdf.setTextColor(0,0,0,0);

    lines.forEach((line, index) => {
      pdf.text(line, 10, 10 + ((index+1) * 10000));
    });

    app.unmount();
    tempDiv.remove();

    if (download) {
      const firstName = resumeData.contact?.firstName?.length ? resumeData.contact?.firstName : "name";
      const lastName = resumeData.contact?.lastName?.length ? resumeData.contact?.lastName : "";
      const jobTitle = resumeData.contact?.firstName?.length ? resumeData.contact?.jobTitle : "";
      pdf.save(firstName + "_" + lastName + "_" + jobTitle + ".pdf");
    }

    return pdf.output("datauristring");
  }
  else{

    const tempDiv = document.createElement("div");
    tempDiv.style.position = "absolute";
    tempDiv.style.left = "-9999px";
    tempDiv.style.height = "auto";
    document.body.appendChild(tempDiv);

    const app = createApp(currentTemplate.component, { cvData: resumeData, adjust:"true" });
    app.use(myStore);
    app.use(i18n);
    app.mount(tempDiv);

    await new Promise(resolve => requestAnimationFrame(resolve));

    const canvas = await html2canvas(tempDiv, {
      scale: scaleFactor,
      useCORS: true,
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.98);

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });
    const imgProps = pdf.getImageProperties(imgData);

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    const longPdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [pdfWidth, pdfHeight],
    });

    longPdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

    const textContent = tempDiv.innerText || tempDiv.textContent || "";
    const lines = textContent.split("\n");

    longPdf.setTextColor(0,0,0,0);

    lines.forEach((line, index) => {
      longPdf.text(line, 10, 10 + ((index+1) * 10000));
    });

    app.unmount();
    tempDiv.remove();

    if (download) {
      const firstName = resumeData.contact?.firstName?.length ? resumeData.contact?.firstName : "name";
      const lastName = resumeData.contact?.lastName?.length ? resumeData.contact?.lastName : "";
      const jobTitle = resumeData.contact?.firstName?.length ? resumeData.contact?.jobTitle : "";
      longPdf.save(firstName + "_" + lastName + "_" + jobTitle + ".pdf");
    }

    return longPdf.output("datauristring");
  }
};