<script setup lang="ts">
import { ref } from "vue";

import { useI18n } from "vue-i18n";

import type { Resume } from "@/composables/types";
import { deleteLibraryEntry } from "@/services/libraryService";

const { t } = useI18n();

const inputValue = ref("");

const { id, resumes } = defineProps<{id: string, resumes: Resume[]}>();
const emit = defineEmits(["closeModal", "updateLibrary"]);

const closeModal = () => {
  emit("closeModal");
};

const confirmDelete = async () => {
  await deleteLibraryEntry(id);
  emit("updateLibrary", resumes.filter((resume) => {
    return resume.id !== id;
  }));
  closeModal();
};

</script>

<template>
  <div class="modal-backdrop">
    <div class="modal">
      <button class="close-button" @click="closeModal">X</button>
      <div class="modal-inputs">
        <p>{{ t("DeleteSure") }}</p>
        <input v-model="inputValue" type="text" :placeholder="t('DeleteConfirm')" />
        <button :disabled="inputValue.toLowerCase() !== t('Delete')" @click="confirmDelete">{{ t('Delete') }}</button>
      </div>
    </div>
  </div>
</template>

<style scoped>

.modal-inputs{
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 20px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  width: 500px;
  height: 350px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: black;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

input {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 20px);
}

button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.7;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
