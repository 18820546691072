<script setup lang="ts">

import { ref, watch } from "vue";

import { useI18n } from "vue-i18n";

import { useAuth } from "@/auth/useAuth";
import { modifyUserInfo } from "@/services/userService";

const { t } = useI18n();

const { user } = useAuth();
const isTipsToggled = ref(false);

watch(
  () => user.value.details,
  (details) => {
    if (details) {
      isTipsToggled.value = details.updatesAndTips;
    }
  },
  { immediate: true , deep: true },
);

const toggleTipsSwitch = async () => {
  await modifyUserInfo(user.value.details?.uid || "", { updatesAndTips: isTipsToggled.value });
  isTipsToggled.value = !isTipsToggled.value;
};

</script>

<template>
  <div class="email-notifications">
    <span class="email-notifications-text">{{ t('Account.Title') }}</span>
    <div class="email-notifications-div">
      <div class="toggle-text-div">
        <div class="toggle-div" :class="{ 'toggled-div': isTipsToggled }" @click="toggleTipsSwitch">
          <div :class="{ toggle: true, toggled: isTipsToggled }"></div>
        </div>
        <span class="tips-text">{{ t('Account.ResumeTips') }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>

.tips-text{
    color: #909090;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 21px;
}

.toggle-text-div{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.toggle-text-div-v2{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
}

.toggle-div {
  cursor: pointer;
  display: block;
  position: relative;
  width: 40px;
  height: 20px;
  pointer-events: auto;
  border-radius: 40px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.08);
  background-color: rgba(255, 255, 255, 1); /* Default non-toggled state */
  border: 1px solid rgba(84, 56, 218, 1);
  transition: background-color 0.3s ease; /* Add transition for smooth background color change */
}

/* New class for toggled state */
.toggled-div {
  background-color: rgba(84, 56, 218, 0.5); /* Adjust the alpha for the desired fill effect */
}

.toggle {
  display: block;
  position: absolute;
  top: 50%;
  left: 3px;
  width: calc(50% - 6px);
  height: calc(100% - 6px);
  transform: translateY(-50%);
  transition: left 0.3s ease;
  border-radius: 40px;
  background-color: rgba(84, 56, 218, 1);
}

.toggled {
  left: calc(50% + 3px);
}

.email-notifications-div{
    display: flex;
    flex-direction: column;
    width: 957px;
  height: 140px;
  background-color: #f0f0f0;
  border-radius: 26px;
  align-items: flex-start;
  padding: 50px;
}

.email-notifications{
    width: 65%;
    margin-top: 100px;
    align-items: center;
}

.email-notifications-text{
    color: #030303;
    font-size: 24px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 31px;
    align-self: flex-start;
    display: flex;
    margin-bottom: 50px;
}

</style>