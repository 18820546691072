<script setup lang="ts">
import { ref, onMounted, watch, onUnmounted } from "vue";

import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import type { RenderTask } from "pdfjs-dist";
import ColorInput from "vue-color-input";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import { useAuth } from "@/auth/useAuth";
import AuthModal from "@/components/AuthModal.vue";
import UpgradePremium from "@/components/UpgradePremium.vue";
import myStore from "@/store/index";
import { previewPdf } from "@/utils/previewPdf";
import { returnTemplateComponent } from "@/utils/templateComponent";

import DropdownSelect from "../components/DropdownSelect.vue";

import PreviewTemplateBebek from "./cv-preview/cv-preview-templates/PreviewTemplateBebek.vue";
import PreviewTemplateBussiness from "./cv-preview/cv-preview-templates/PreviewTemplateBussiness.vue";
import PreviewTemplateCasual from "./cv-preview/cv-preview-templates/PreviewTemplateCasual.vue";
import PreviewTemplateClassic from "./cv-preview/cv-preview-templates/PreviewTemplateClassic.vue";
import PreviewTemplate from "./cv-preview/cv-preview-templates/PreviewTemplateDefault.vue";
import PreviewTemplateFancy from "./cv-preview/cv-preview-templates/PreviewTemplateFancy.vue";
import PreviewTemplateLiner from "./cv-preview/cv-preview-templates/PreviewTemplateLiner.vue";
import PreviewTemplateModa from "./cv-preview/cv-preview-templates/PreviewTemplateModa.vue";
import PreviewTemplateProfessional from "./cv-preview/cv-preview-templates/PreviewTemplateProfessional.vue";
// eslint-disable-next-line import/no-unresolved
import "pdfjs-dist/build/pdf.worker";
import CvEvaluated from "./CvEvaluated.vue";
import CvEvaluator from "./CvEvaluator.vue";
import "pdfjs-dist/web/pdf_viewer.css";

GlobalWorkerOptions.workerSrc = "pdfjs-dist/build/pdf.worker.js";
type DoneCallback = () => void;
const { t } = useI18n();

const screenWidth = ref(window.innerWidth);

const updateScreenWidth = () => {
  screenWidth.value = window.innerWidth;
};

const isModalVisible = ref(false);
const currentTemplate = ref({ component: PreviewTemplateClassic });
const { user } = useAuth();
const modalAuthenticationVisible = ref(false);
const modalUpgradePremiumVisible = ref(false);
const store = useStore();
const customColor =ref("");
const customColorPicked = ref(false);

const selectedFont = ref("Roboto");
const fontOptions = ["Roboto", "Arial", "Helvetica", "Montserrat" ,"Times New Roman"];

const colors = [
  { name: "black", cssClass: "palette-black" },
  { name: "grey", cssClass: "palette-grey" },
  { name: "orange", cssClass: "palette-orange" },
  { name: "green", cssClass: "palette-green" },
  { name: "blue", cssClass: "palette-blue" },
];

const isColorName = (cvColor: string): boolean => {
  return colors.some(color => color.name === cvColor);
};

const fontSize = ref(16);

const increaseFontSize = () => {
  if ( fontSize.value < 20) {
    fontSize.value += 1;
  }
};

const decreaseFontSize = () => {
  if (fontSize.value > 14 ) {
    fontSize.value -= 1;
  }
};

watch(fontSize, (newSize) => {
  store.commit("updateResumeFontSize", {  data: newSize - 6 });
});

watch(selectedFont, (newFont) => {
  store.commit("updateResumeFontFamily", {  data: newFont });
});

defineProps<{
  evaluated: boolean,
  generalScore: number,
  generalScoreExplanation: string
}>();
const singlePage = ref(true);

const emit = defineEmits(["evaluate"]);

const openAuthenticationModal = () => {
  modalAuthenticationVisible.value = true;
};

const openUpgradePremiumModal = () => {
  modalUpgradePremiumVisible.value = true;
};

const showTemplateModal = () => {
  isModalVisible.value = true;
};

const evaluate = (cvText: string, jobTitle: string, doneCallback: DoneCallback) => {
  if(user.value.details?.accountStatus === "Free"){
    openUpgradePremiumModal();
    return;
  }
  emit("evaluate", cvText, jobTitle, doneCallback);
};

const download = async () => {

  if(user.value){
    if(user.value.details?.accountStatus === "Free"){
      openUpgradePremiumModal();
      return;
    }
    await previewPdf(currentTemplate.value, myStore,  store.state.cvData, true, singlePage.value );
  }
  else{
    openAuthenticationModal();
  }
};

const scale = ref(1);
const showPreview = ref<boolean>(true);

onMounted( async () => {
  const containerWidth = document.querySelector(".cv-container")?.clientWidth || 0;
  const scaleFactor = containerWidth / 794;
  scale.value = scaleFactor < 1 ? scaleFactor : 1;
});

const pdfCanvas = ref<HTMLCanvasElement | null>(null);

const currentPage = ref(1);
const totalPages = ref(0);
const pdfDataUrl = ref<string | undefined>("");

let currentRenderTask: RenderTask | null = null;

const loadPdf = async (pdfDataUrl: string, pageNum: number) => {
  try {
    if (currentRenderTask) {
      currentRenderTask.cancel();
    }

    const doc = await getDocument(pdfDataUrl).promise;
    totalPages.value = doc.numPages;
    const page = await doc.getPage(pageNum);

    const viewport = page.getViewport({ scale: 10 });
    const canvas = pdfCanvas.value;
    if (canvas) {
      const context = canvas.getContext("2d");
      if (context) {
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        currentRenderTask = page.render(renderContext);
        await currentRenderTask.promise;
        currentRenderTask = null;
      } else {
        console.error("Failed to get 2D context from canvas.");
      }
    } else {
      console.error("Canvas element is null.");
    }
  } catch (error) {
    console.error("Error loading PDF document:", error);
  }
};

const goToPrevPage = async () => {
  if (currentPage.value > 1) {
    currentPage.value--;

    loadPdf(pdfDataUrl.value as string, currentPage.value);
  }
};

const goToNextPage = async () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;

    loadPdf(pdfDataUrl.value as string, currentPage.value);
  }
};

onMounted(async () => {
  currentTemplate.value = returnTemplateComponent(store.state.cvData);
  pdfDataUrl.value = await previewPdf(currentTemplate.value, myStore, store.state.cvData, false, singlePage.value);
  loadPdf(pdfDataUrl.value as string, 1);
  currentPage.value = 1;
  showPreview.value = false;
  if(!isColorName(myStore.state.cvData.resumeColor)){
    customColorPicked.value = true;
    customColor.value = myStore.state.cvData.resumeColor;
  }
  selectedFont.value = myStore.state.cvData.resumeFontFamily;
  fontSize.value = myStore.state.cvData.resumeFontSize + 6;

});

watch(
  () => store.state.cvData,
  async () => {
    currentTemplate.value = returnTemplateComponent(store.state.cvData);
    pdfDataUrl.value = await previewPdf(currentTemplate.value, myStore, store.state.cvData, false, singlePage.value);
    loadPdf(pdfDataUrl.value as string, 1);
    currentPage.value = 1;
  },
  {
    deep: true,
  },
);

const selectTemplate = async (templateName: string) => {
  switch (templateName) {
  case "classic":
    currentTemplate.value.component = PreviewTemplateClassic;
    store.commit("updateSelectedTemplate", {  data: "Classic" });
    break;
  case "casual":
    currentTemplate.value.component = PreviewTemplateCasual;
    store.commit("updateSelectedTemplate", {  data: "Casual" });
    break;
  case "business":
    currentTemplate.value.component = PreviewTemplateBussiness;
    store.commit("updateSelectedTemplate", {  data: "Business" });
    break;
  case "professional":
    currentTemplate.value.component = PreviewTemplateProfessional;
    store.commit("updateSelectedTemplate", {  data: "Professional" });
    break;
  case "fancy":
    currentTemplate.value.component = PreviewTemplateFancy;
    store.commit("updateSelectedTemplate", {  data: "Fancy" });
    break;
  case "bebek":
    currentTemplate.value.component = PreviewTemplateBebek;
    store.commit("updateSelectedTemplate", {  data: "Bebek" });
    break;
  case "liner":
    currentTemplate.value.component = PreviewTemplateLiner;
    store.commit("updateSelectedTemplate", {  data: "Liner" });
    break;
  case "moda":
    currentTemplate.value.component = PreviewTemplateModa;
    store.commit("updateSelectedTemplate", {  data: "Moda" });
    break;
  default:
    currentTemplate.value.component = PreviewTemplate;
    store.commit("updateSelectedTemplate", {  data: "Default" });
  }
  isModalVisible.value = false;
  pdfDataUrl.value = await previewPdf(currentTemplate.value, myStore,  store.state.cvData, false, singlePage.value );
  loadPdf(pdfDataUrl.value as string, 1);
  currentPage.value = 1;

};

const selectColor = (color: string) => {
  customColorPicked.value = false;
  store.commit("updateResumeColor", {  data: color });
};
const selectCustomColor = () => {
  customColorPicked.value = true;
};

const isBlurred = ref(false);

const handleKeydown = (event: KeyboardEvent) => {
  if (event.code === "PrintScreen" ||
      (event.metaKey || event.ctrlKey)
  ) {
    isBlurred.value = true;
  } else {
    isBlurred.value = false;
  }
};

const handleMouseEvents = (event: MouseEvent) => {
  if (event.button === 2 || (event.ctrlKey && event.button === 0)) {
    isBlurred.value = true;
  } else if (event.button === 0) {
    isBlurred.value = false;
  }
};

onMounted(() => {
  document.addEventListener("keydown", handleKeydown);
  document.addEventListener("mousedown", handleMouseEvents);
  window.addEventListener("resize", updateScreenWidth);
});

onUnmounted(() => {
  document.removeEventListener("keydown", handleKeydown);
  document.removeEventListener("mousedown", handleMouseEvents);
  window.removeEventListener("resize", updateScreenWidth);
});

const closeModal = () => {
  if(customColorPicked.value){
    store.commit("updateResumeColor", {  data: customColor.value });
  }
  isModalVisible.value = false;
};

watch(customColor, (newValue) => {
  setTimeout(() => {
    if (customColor.value === newValue) {
      selectCustomColor();
      store.commit("updateResumeColor", {  data: customColor.value });
    }
  }, 800);
});

</script>

<template>
  <AuthModal :isVisible="modalAuthenticationVisible" @update:visible="modalAuthenticationVisible = $event" />
  <UpgradePremium v-if="modalUpgradePremiumVisible" showFeatures :premiumReasonText="t('UpgradePremium.UpgradeToPremiumText')" @close="modalUpgradePremiumVisible = false" />
  <div v-if="isModalVisible" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal()">&times;</span>
      <span class="select-template-text">{{ t("cvCreationView.SelectTemplate") }}</span>
      <div class="template-options">
        <div class="template-options-element" @click="selectTemplate('default')">
          <div class="template-options-image" :style="{ backgroundImage: 'url(' + require('@/assets/resumeDefault.png') + ')' }"/>
          <span>{{ t("cvCreationView.DefaultTemplate") }}</span>
        </div>
        <div class="template-options-element" @click="selectTemplate('classic')">
          <div class="template-options-image" :style="{ backgroundImage: 'url(' + require('@/assets/resumeClassic.png') + ')' }"/>
          <span>{{ t("cvCreationView.Classic") }}</span>
        </div>
        <div class="template-options-element" @click="selectTemplate('casual')">
          <div class="template-options-image" :style="{ backgroundImage: 'url(' + require('@/assets/resumeCasual.png') + ')' }"/>
          <span>{{ t("cvCreationView.Casual") }}</span>
        </div>
        <div class="template-options-element" @click="selectTemplate('business')">
          <svg v-if="user.details?.accountStatus==='Free'" class="lock-icon"  viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z">
            </path>
            <path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6zM18 20H6V10h12v10z">
            </path>
          </svg>
          <div class="template-options-image" :style="{ backgroundImage: 'url(' + require('@/assets/resumeBusiness.png') + ')' }"/>
          <span>{{ t("cvCreationView.Business") }}</span></div>
        <div class="template-options-element" @click="selectTemplate('professional')">
          <svg v-if="user.details?.accountStatus==='Free'" class="lock-icon"  viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z">
            </path>
            <path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6zM18 20H6V10h12v10z">
            </path>
          </svg>
          <div class="template-options-image" :style="{ backgroundImage: 'url(' + require('@/assets/resumeProfessional.png') + ')' }"/>
          <span>{{ t("cvCreationView.Professional") }}</span>
        </div>
        <div class="template-options-element" @click="selectTemplate('fancy')">
          <svg v-if="user.details?.accountStatus==='Free'" class="lock-icon"  viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z">
            </path>
            <path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6zM18 20H6V10h12v10z">
            </path>
          </svg>
          <div class="template-options-image" :style="{ backgroundImage: 'url(' + require('@/assets/resumeBusiness.png') + ')' }"/>
          <span>{{ t("cvCreationView.Fancy") }}</span>
        </div>
        <div class="template-options-element" @click="selectTemplate('bebek')">
          <svg v-if="user.details?.accountStatus==='Free'" class="lock-icon"  viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z">
            </path>
            <path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6zM18 20H6V10h12v10z">
            </path>
          </svg>
          <div class="template-options-image" :style="{ backgroundImage: 'url(' + require('@/assets/resumeBusiness.png') + ')' }"/>
          <span>Bebek</span>
        </div>
        <div class="template-options-element" @click="selectTemplate('liner')">
          <svg v-if="user.details?.accountStatus==='Free'" class="lock-icon"  viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z">
            </path>
            <path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6zM18 20H6V10h12v10z">
            </path>
          </svg>
          <div class="template-options-image" :style="{ backgroundImage: 'url(' + require('@/assets/resumeBusiness.png') + ')' }"/>
          <span>Liner</span>
        </div>
        <div class="template-options-element" @click="selectTemplate('moda')">
          <svg v-if="user.details?.accountStatus==='Free'" class="lock-icon"  viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z">
            </path>
            <path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6zM18 20H6V10h12v10z">
            </path>
          </svg>
          <div class="template-options-image" :style="{ backgroundImage: 'url(' + require('@/assets/resumeBusiness.png') + ')' }"/>
          <span>Moda</span>
        </div>
      </div>
    </div>
  </div>
  <div class="icon-button-container-preview">
    <button class="showTemplateButton" @click="showTemplateModal">
      <div class="add-more">
        <font-awesome-icon :icon="['fas', 'file-circle-plus']" class="white-icon" />
        <p>{{ t("MoreTemplates") }}</p>
      </div>
    </button>
    <div class="middle-section">
      <div class="color-palettes">
        <button
          v-for="color in colors"
          :key="color.name"
          :class="color.cssClass"
          :style="{ border: myStore.state.cvData.resumeColor === color.name && !customColorPicked ? '2px solid #000' : 'none'}"
          @click="selectColor(color.name)">
        </button>
        <div :style="{  borderRadius: '50%', position: 'relative', marginTop: '5px' }"  >
          <div :style="{ border: customColorPicked ? '2px solid #000' : 'none', position: 'absolute',
                         top: '0',
                         left: '0',
                         width: '33px',
                         height: '33px',
                         borderRadius: '50%',
                         zIndex: 0,
                         marginTop: '3.5px ',
                         marginLeft: '3.15px ' }" />
          <svg fill="none" height="40" viewBox="0 0 1024 1024" width="40" xmlns="http://www.w3.org/2000/svg"><path d="m912 512c0 70.215-18.482 139.192-53.59 200l-86.602-50c26.33-45.606 40.192-97.339 40.192-150z" fill="#f45542"/><path d="m858.387 712.04c-35.114 60.804-85.615 111.293-146.427 146.393l-49.99-86.608c45.609-26.325 83.484-64.192 109.82-109.795z" fill="#dd3242"/><path d="m711.963 858.431c-60.811 35.101-129.791 53.576-200.005 53.569l.01-100c52.661.006 104.396-13.851 150.005-40.177z" fill="#af2f79"/><path d="m112 512c0 70.215 18.482 139.192 53.59 200l86.602-50c-26.33-45.606-40.192-97.339-40.192-150z" fill="#2656ab"/><path d="m165.613 712.04c35.114 60.804 85.615 111.293 146.427 146.393l49.99-86.608c-45.609-26.325-83.484-64.192-109.82-109.795z" fill="#7943a3"/><path d="m312.037 858.431c60.811 35.101 129.791 53.576 200.005 53.569l-.01-100c-52.661.006-104.396-13.851-150.005-40.177z" fill="#a23ca2"/><path d="m112 512c0-70.215 18.482-139.192 53.59-200l86.602 50c-26.33 45.606-40.192 97.339-40.192 150z" fill="#019a85"/><path d="m165.613 311.96c35.114-60.804 85.615-111.293 146.427-146.393l49.99 86.608c-45.609 26.325-83.484 64.192-109.82 109.795z" fill="#01ae5c"/><path d="m312.037 165.569c60.811-35.101 129.791-53.576 200.005-53.569l-.01 100c-52.661-.006-104.396 13.851-150.005 40.177z" fill="#a1cf49"/><path d="m912 512c0-70.215-18.482-139.192-53.59-200l-86.602 50c26.33 45.606 40.192 97.339 40.192 150z" fill="#f97827"/><path d="m858.387 311.96c-35.114-60.804-85.615-111.293-146.427-146.393l-49.99 86.608c45.609 26.325 83.484 64.192 109.82 109.795z" fill="#fca313"/><path d="m711.963 165.569c-60.811-35.101-129.791-53.576-200.005-53.569l.01 100c52.661-.006 104.396 13.851 150.005 40.177z" fill="#ffe503" /></svg>
          <ColorInput v-model="customColor"/>
        </div>
      </div>

      <div class="dropdown-font-selecter">
        <DropdownSelect v-model="selectedFont" :options="fontOptions" />
        <div class="font-size-adjuster">
          <button class="adjust-button increase-button" @click="increaseFontSize">+</button>
          <span class="font-size-display" :style="{ fontSize: fontSize + 'px' }">Aa</span>
          <button class="adjust-button decrease-button" @click="decreaseFontSize">-</button>
        </div>
      </div>

    </div>
    <button class="download-button" @click="download">{{ t("DownloadCv") }}</button>
  </div>
  <CvEvaluator v-if="!evaluated" @evaluate="evaluate"/>
  <CvEvaluated v-if="evaluated" :general-score="generalScore" :general-score-explanation="generalScoreExplanation"/>

  <div style="display: flex; gap: 15px;">
    <button
      class="page-button"
      :class="{ 'grey-background': singlePage }"
      @click="() => singlePage = true"
    >
      {{ t("cvCreationView.SinglePage") }}
    </button>
    <button
      class="page-button"
      :class="{ 'grey-background': !singlePage }"
      @click="async () => {
        currentTemplate = returnTemplateComponent(store.state.cvData);
        singlePage = false;
        pdfDataUrl = await previewPdf(currentTemplate, myStore, store.state.cvData, false, singlePage);
        loadPdf(pdfDataUrl as string, 1);
        currentPage = 1;

      }"
    >
      {{ t("cvCreationView.MultiplePage") }}
    </button>

  </div>

  <div v-if="totalPages && !singlePage" class="cv-preview-canvas" :class="{ blurred: isBlurred }">
    <div class="navigate-buttons">
      <button :disabled="currentPage === 1" @click="goToPrevPage"><font-awesome-icon :icon="['fas', 'chevron-left']"/></button>
      <p class="page-text" >{{ currentPage }} / {{ totalPages }}</p>
      <button :disabled="currentPage === totalPages" @click="goToNextPage"><font-awesome-icon :icon="['fas', 'chevron-right']" /></button>
    </div>
    <div style="display: flex;">
      <canvas ref="pdfCanvas" class="cv-preview" :style="{ '--scale-factor': 0.80 }"></canvas>
    </div>

  </div>
  <div v-if="singlePage" class="cv-preview-single"  :class="{ blurred: isBlurred }" :style="{ '--scale-factor': screenWidth / 1512 }">
    <PreviewTemplate v-if="currentTemplate.component.__name === 'PreviewTemplateDefault'" :cv-data="store.state.cvData" :adjust="true"/>
    <PreviewTemplateClassic v-if="currentTemplate.component.__name === 'PreviewTemplateClassic'" :cv-data="store.state.cvData" :adjust="true"/>
    <PreviewTemplateCasual v-if="currentTemplate.component.__name === 'PreviewTemplateCasual'" :cv-data="store.state.cvData" :adjust="true"/>
    <PreviewTemplateBussiness v-if="currentTemplate.component.__name === 'PreviewTemplateBussiness'" :cv-data="store.state.cvData" :adjust="true"/>
    <PreviewTemplateProfessional v-if="currentTemplate.component.__name === 'PreviewTemplateProfessional'" :cv-data="store.state.cvData" :adjust="true"/>
    <PreviewTemplateFancy v-if="currentTemplate.component.__name === 'PreviewTemplateFancy'" :cv-data="store.state.cvData" :adjust="true"/>
    <PreviewTemplateBebek v-if="currentTemplate.component.__name === 'PreviewTemplateBebek'" :cv-data="store.state.cvData" :adjust="true"/>
    <PreviewTemplateLiner v-if="currentTemplate.component.__name === 'PreviewTemplateLiner'" :cv-data="store.state.cvData" :adjust="true"/>
    <PreviewTemplateModa v-if="currentTemplate.component.__name === 'PreviewTemplateModa'" :cv-data="store.state.cvData" :adjust="true"/>
  </div>

</template>

<style>

.dropdown-font-selecter{
  display: flex;
  flex-direction: row;
  gap: 15px
}

.font-size-adjuster {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.adjust-button {
  background: none;
  border: none;
  color: #030303;
  font-size: 24px;
  cursor: pointer;
  font-family: "Poppins";
  line-height: 31px;
}

.increase-button:hover,
.decrease-button:hover {
  font-weight: bold;
}

.font-size-display {
  color: #030303;
  font-size: 24px;
  font-family: "Poppins";
  line-height: 31px;
}

.icon-button-container-preview{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin-bottom: 22px;
}

.middle-section{
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  gap: 5px;
}

.page-button{
  padding: 8px 16px;
  border: none;
  border-radius: 7px;
  background-color: #f2f2f2;
  cursor: pointer;
}

.grey-background {
  background-color: rgb(93, 93, 93);
  color: white;
}

.lock-icon{
  color: #808080;
  fill: #808080;
  font-size: 40px;
  position: absolute;
  width: 40px;
  height: 40px;
}

.palette-black{
  width: 28px;
  height: 27px;
  background-color: #818181;
  border-radius: 50%;
}

.palette-grey{
  width: 28px;
  height: 27px;
  background-color: #c2c2c2;
  border-radius: 50%;
}

.palette-orange{
  width: 28px;
  height: 27px;
  background-color: #ff7f50;
  border-radius: 50%;
}
.palette-green{
  width: 28px;
  height: 27px;
  background-color: #2e8b57;
  border-radius: 50%;
}
.palette-blue{
  width: 28px;
  height: 27px;
  background-color: #005db9;
  border-radius: 50%;
}

.color-input__box {
  width: 24px;
  height: 23px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  margin-top: 8.4px;
  margin-left: 8px;
}

.color-palettes{
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.template-options-image{
  width: 213px;
  height: 309px;
  border-radius: 8px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.template-options-element{
display: flex;
flex-direction: column;
width: 25%;
align-items: center;
justify-content: center;
gap: 10px;
margin: 20px;
}

.select-template-text{
  color: #030303;
  font-size: 24px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 28px;
}

.showTemplateButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 860px;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.template-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  cursor: pointer;
  padding: 10px;
  margin-top: 25px;
}

.cv-preview-canvas{
  height: 1000px;
  width: 100%;
}

.cv-preview {
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin: auto;
  overflow: hidden;
  background: white;
  transform-origin: top center;
  transform: scale(var(--scale-factor));
  margin-bottom: 30px;
}

.cv-preview-single {
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin-top: 50px;
  overflow: hidden;
  background: white;
  transform-origin: top center;
  transform: scale(var(--scale-factor));
  margin-bottom: 30px;
}

.cv-preview h2, .cv-preview h3 {
  margin: 10px 0;
}

.cv-preview p {
  margin: 5px 0;
}

.navigate-buttons {
  display: flex;
  justify-content: center;
  align-items: center;

}

.navigate-buttons button {
  color: rgb(255, 255, 255);
  background-color: #909090;;
  border: none;
  padding-top: 50px;
  padding-bottom: 50px;
}
.navigate-buttons .page-text{
  color: rgb(255, 255, 255);
}

.navigate-buttons button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

@media print {
  .cv-preview {
    transform: scale(1);
  }
  .navigate-buttons {
    display: none;
  }
  .resume {
    width: 100%;
    max-width: 794px;
    margin: 0 auto;
    box-shadow: none;
  }
  .resume_left, .resume_right {
    width: 100%;
    padding: 20px;
    box-shadow: none;
    page-break-inside: avoid;
  }
  .r_skills, .r_hobbies, .r_education, .r_jobs, .r_projects {
    page-break-inside: avoid;
  }
}
.cv-preview-canvas.blurred {
  filter: blur(10px);
}
.cv-preview-single.blurred {
  filter: blur(10px);
}

</style>
