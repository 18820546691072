<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";

import DOMPurify from "dompurify";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";
import draggable from "vuedraggable";
import { useStore } from "vuex";

import { useAuth } from "@/auth/useAuth";
import AuthModal from "@/components/AuthModal.vue";
import UpgradePremium from "@/components/UpgradePremium.vue";
import type { Experience, MissingInputField , SectionScore } from "@/composables/types";
import { enhanceExperience } from "@/services/enhanceService";
import { modifyLibraryEntry } from "@/services/libraryService";
import { validateInputs } from "@/utils/validateCvDataInputs";

import  CvSectionScore  from "../../components/CvSectionScore.vue";
import DescriptionEnhanceModal from "../../components/DescriptionEnhanceModal.vue";
import EnhanceButton from "../../components/EnhanceButton.vue";
import RichTextEditor from "../../components/RichTextEditor.vue";
import { formatDate } from "../../utils/formatDate";
import { initialStateExperience } from "../../utils/initialSectionStates";
import { removeBackgroundColorsFromSection } from "../../utils/removeDescriptionBackgroundColors";

const { t } = useI18n();

const props = defineProps<{
  cvId: string,
  evaluated: boolean,
  scoreDetails: SectionScore | undefined
}>();

const { user } = useAuth();

const store = useStore();
const showAdd = ref(true);
const isAdding = ref(false);
const showDescriptionModal = ref(false);
const enhanced = ref(false);
const isLoading = ref(false);
const upgradePremiumVisible = ref(false);
const authModalVisible = ref(false);
const missingFields = ref<string[]>([]);
const richTextEditorRef = ref<any>();
const creditNotEnough = ref(false);

const closeModal = () => {
  showDescriptionModal.value = false;
};
const openModal = () => {
  showDescriptionModal.value = true;

};

const summaryEnhancements = ref([]);

const addExperience = ref<Experience>(initialStateExperience(uuidv4()));

watch(
  () => store.state.cvData,
  async (newcvData) => {
    try {
      await modifyLibraryEntry(props.cvId, user.value.details?.uid || "", removeBackgroundColorsFromSection(newcvData, "experiences"));
      missingFields.value = validateInputs(store.state.cvData, addExperience.value);
    } catch (error) {
      console.error("Failed to update library entry:", error);
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

const experiences = computed({
  get: () => store.state.cvData.experiences,
  set: (value) => store.commit("updateCvSection", { section: "experiences", data: value }),
});

console.log(experiences);

const handleCurrentWork = () => {
  if (addExperience.value.isCurrentWork) {
    addExperience.value.endDate = t("cvCreationView.Present");
  } else {
    addExperience.value.endDate = "";
  }
  store.dispatch("updateCvItem", {
    section: "experiences",
    itemId: addExperience.value.id,
    newData: addExperience.value,
  });
};

const updateField = (fieldName: keyof Experience, event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  if (fieldName === "company" || fieldName === "title" || fieldName === "location" || fieldName === "startDate" || fieldName === "endDate") {
    addExperience.value[fieldName] = DOMPurify.sanitize(value);
  } if (fieldName === "isCurrentWork") {
    addExperience.value[fieldName] = value === "true";
  }
  if(!isAdding.value){
    store.dispatch("updateCvItem", {
      section: "experiences",
      itemId: addExperience.value.id,
      newData: addExperience.value,
    });
  }
};

const updateDescription = (newHtmlContent: string) =>  {
  if(addExperience.value){
    addExperience.value.description = DOMPurify.sanitize(newHtmlContent);
    store.dispatch("updateCvItem", {
      section: "experiences",
      itemId: addExperience.value.id,
      newData: addExperience.value,
    });
  }

};
const updateDescriptionEnhance = (newHtmlContent: string) =>  {
  addExperience.value.description = DOMPurify.sanitize(newHtmlContent);
  store.dispatch("updateCvItem", {
    section: "experiences",
    itemId: addExperience.value?.id,
    newData: addExperience.value,
  });
  setTimeout(() => {
    richTextEditorRef.value?.updateInnerHtml();
  }, 500);
};

const discardEnhanced = () => {
  enhanced.value = false;
};

const getSummaryEnhancement = async() => {
  if (!user.value.authUser) {
    authModalVisible.value = true;
    return;
  }
  if (user.value.details?.accountStatus !== "Premium") {
    upgradePremiumVisible.value = true;
    return;
  }
  missingFields.value = validateInputs(store.state.cvData, addExperience.value);
  isLoading.value = true;
  discardEnhanced();
  try {
    const { result: response, error: enhanceError } = await enhanceExperience(addExperience.value?.description || "", t("Locale"), t("SectionStringEducation"), user.value.details.id || "");
    if (enhanceError?.value && enhanceError.value.indexOf("402") !== -1) {
      creditNotEnough.value = true;
      return;
    }

    const jsonString2 = response?.enhancedSummaries;
    const jsonObj = JSON.parse(jsonString2 || "");

    summaryEnhancements.value = jsonObj.enhanced_summaries;
    enhanced.value = true;

    openModal();

  } catch (e) {
    console.error("Error during enhancement or parsing:", e);
  } finally {
    isLoading.value = false;
  }

};
onMounted;

const isFieldMissing = computed(() => {
  return (field: MissingInputField) => missingFields.value.includes(field);
});
</script>

<template>
  <AuthModal v-if="authModalVisible" @update:visible="authModalVisible = $event"/>
  <UpgradePremium v-if="creditNotEnough" :premiumReasonText="t('NotEnoughCreditsTextPremium')" :premiumHeader="t('NotEnoughCreditsHeaderPremium')" @close="creditNotEnough = false"/>
  <UpgradePremium v-if="upgradePremiumVisible" showFeatures @close="upgradePremiumVisible = false"/>
  <div class="experience-container">
    <div class="score-container">
      <div class="details-container">
        <h2>{{ t('Experience') }}</h2>
        <p>{{ t('ExperienceSection.FillInYourWorkExperienceBackground') }}</p>
      </div>
      <CvSectionScore v-if="scoreDetails && evaluated" :scoreDetails="scoreDetails"/>
    </div>
    <div class="button-wrapper">
      <button
        v-if="showAdd"
        class="button-wrapper-button"
        @click="
          () => {
            isAdding = true;
            showAdd = false;
          }
        "
      >
        {{ t('ExperienceSection.AddAWorkExperience') }} <font-awesome-icon icon="plus" />
      </button>
      <div v-if="!showAdd">
        <div class="input-row">
          <div class="input-wrapper">
            <label for="jobTitle">{{ t('ExperienceSection.JobTitle') }}</label>
            <input
              id="jobTitle"
              type="text"
              :placeholder="t('ExperienceSection.JobTitle')"
              :value="addExperience.title"
              :class="{'error': enhanced && isFieldMissing('title')}"
              @blur="updateField('title', $event)"
            />
            <span v-if="enhanced && isFieldMissing('title')" class="error-message">{{ t("InputValidation.MissingJobTitle") }}</span>
          </div>

          <div class="input-wrapper">
            <label for="company">{{ t('ExperienceSection.Company') }}</label>
            <input
              id="company"
              type="text"
              :placeholder="t('ExperienceSection.Company')"
              :value="addExperience.company"
              :class="{'error': enhanced && isFieldMissing('company')}"
              @blur="updateField('company', $event)"
            />
            <span v-if="enhanced && isFieldMissing('company')" class="error-message">{{ t("InputValidation.MissingCompanyName") }}</span>
          </div>
        </div>
        <div class="input-row">
          <div class="input-wrapper">
            <label for="location">{{ t('ExperienceSection.Location') }}</label>
            <input
              id="location"
              type="text"
              :placeholder="t('ExperienceSection.Location')"
              :value="addExperience.location"
              :class="{'error': enhanced && isFieldMissing('location')}"
              @blur="updateField('location', $event)"
            />
            <span v-if="enhanced && isFieldMissing('location')" class="error-message">{{ t("InputValidation.MissingLocation") }}</span>
          </div>

          <div class="input-wrapper">
            <label for="startDate">{{ t('ExperienceSection.StartAndEndDate') }}</label>
            <div class="date-container">
              <input
                id="startDate"
                class="date-input"
                placeholder="MM/YYYY"
                :value="addExperience.startDate"
                :class="{'error': enhanced && isFieldMissing('startDate')}"
                @blur="updateField('startDate', $event)"
                @input="formatDate($event, addExperience, 'startDate')"
              />
              <span v-if="enhanced && isFieldMissing('startDate')" class="error-message">{{ t("InputValidation.MissingStartDate") }}</span>

              <input
                id="endDate"
                class="date-input"
                placeholder="MM/YYYY"
                :value="addExperience.endDate"
                :disabled="addExperience.isCurrentWork"
                :class="{'error': enhanced && isFieldMissing('endDate')}"
                @blur="updateField('endDate', $event)"
                @input="formatDate($event, addExperience, 'endDate')"
              />
              <span v-if="enhanced && isFieldMissing('endDate')" class="error-message">{{ t("InputValidation.MissingEndDate") }}</span>
            </div>
            <div class="checkbox-container">
              <input
                id="currentWork"
                v-model="addExperience.isCurrentWork"
                type="checkbox"
                class="current-work-checkbox"
                @change="handleCurrentWork"
              />
              <label for="currentWork" class="current-work-label">{{ t('ExperienceSection.CurrentWork') }}</label>
            </div>
          </div>
        </div>
        <div class="input-wrapper">
          <DescriptionEnhanceModal v-if="showDescriptionModal" :summaryEnhancements="summaryEnhancements" :currentDescription="addExperience.description || ''" @updateDescription="updateDescriptionEnhance" @closeModal="closeModal" @discardEnhanced="discardEnhanced"/>
          <label for="summary">{{ t('ExperienceSection.Summary') }}</label>
        </div>
        <RichTextEditor ref="richTextEditorRef" :htmlValue="addExperience.description" :enhanced="enhanced" :style="{ height: '150px' }" @updateHtmlValue="updateDescription" @openModal="openModal"/>

        <div class="buttons-div">
          <button
            type="button"
            class="submit-button"
            @click="
              () => {
                if (isAdding) {
                  store.dispatch('addCvItem', {
                    section: 'experiences',
                    item: { ...addExperience, id: uuidv4() },
                  });
                  addExperience = initialStateExperience(uuidv4());
                  isAdding = false;
                }
                addExperience = initialStateExperience(uuidv4());
                showAdd = true;
                enhanced = false;
              }
            "
          >
            {{ isAdding ? t("Add") : t("Save") }}
          </button>
          <button
            v-if="isAdding"
            type="button"
            class="submit-button"
            @click="
              () => {
                showAdd = true;
                isAdding = false;
                enhanced = false;
              }
            "
          >
            {{ t('Discard') }}
          </button>
          <EnhanceButton :isLoading="isLoading" :width="'198px'" :height="'36px'" @enhance="getSummaryEnhancement" />
        </div>
      </div>
    </div>
    <div>
      <draggable
        v-if="showAdd"
        v-model="experiences"
        itemKey="id"
        class="draggable-container"
        tag="div"
      >
        <template #item="{ element, index }">
          <div :key="element.id" class="experience-item">
            <svg class="drag-icon" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0V0z" fill="none"></path>
              <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
            </svg>
            <div class="experience-content">
              <label class="experience-title">{{ element.title }}</label>
              <label class="experience-company">{{ element.company }}</label>
            </div>
            <div class="experience-actions">
              <font-awesome-icon
                icon="edit"
                class="experience-action-icon"
                @click="
                  () => {
                    showAdd = false;
                    addExperience = element;
                  }
                "
              />
              <font-awesome-icon
                icon="trash"
                class="experience-action-icon"
                @click="
                  store.dispatch('removeCvItem', {
                    section: 'experiences',
                    index,
                  })
                "
              />
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<style scoped>

.buttons-div{
  display: flex;
  margin-top: 18px;
}

.error-message {
  color: red;
  font-size: 13px;
}

.details-container {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.score-container {
  display: flex;
  flex-direction: row;
}

.drag-icon {
  color: #c2c2c2;
  fill: #c2c2c2;
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.experience-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.experience-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  border-radius: 3%;
  background-color: #e1dfff;
  min-height: 2.6rem;
  padding: 10px;
}

.experience-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  gap: 15px;
}

.experience-action-icon {
  color: #909090;
}

.experience-title {
  margin-left: 20px;
}

.experience-company {
  color: #909090;
  margin-right: 20px;
}

.checkbox-container {
  display: flex;
  font-size: small;
  gap: 8px;
  align-self: flex-end;
  margin-right: 25%;
}

.current-work-label {
  margin: 0;
  font-size: 0.78rem;
  color: #848484 !important;
}

.current-work-checkbox ~ label::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.current-work-checkbox:checked ~ label::before {
  background-color: rgb(95, 82, 210, 0.7);
}

.current-work-checkbox {
  opacity: 0;
}

.current-work-checkbox ~ label {
  position: relative;
  cursor: pointer;
}

.button-wrapper {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 25px;
}

.button-wrapper-button {
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  gap: 10px;
}

.button-wrapper-button:hover {
  background-color: rgb(95, 82, 210, 0.7);
}

.button-wrapper-button:focus {
  outline: none;
}

.experience-container {
  width: 100%;
  margin: auto;
  background: #fff;
  padding: 20px;
  align-items: flex-start;
}

.experience-container h2 {
  text-align: start;
}

.experience-container p {
  text-align: start;
  color: #7d7d7d;
}

.input-row {
  display: flex;
  gap: 20px;
  margin-bottom: 25px;
  margin-top: 45px;
  align-items: flex-start;
}

.input-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-wrapper .error {
  border: 2px solid red !important;
}

.date-container {
  gap: 30px;
  display: flex;
}

.input-wrapper label {
  display: block;
  margin-bottom: 5px;
  color: #909090;
  font-weight: bold;
}

.input-wrapper input[type="text"],
.input-wrapper input[type="email"],
.input-wrapper input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.input-wrapper input[type="date"] {
  width: 50%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.date-input {
  width: 50%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.continue-button {
  background-color: #aa93f3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
}

.action-button {
  background-color: #aa93f3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
}

.draggable-container {
  margin-top: 25px;
}

.submit-button {
  cursor: pointer;
  width: 120px;
  height: 36px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: #dedede;
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 24px;
  outline: none;
  margin-right: 10px
}

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
</style>
